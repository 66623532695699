import React, { FunctionComponent } from "react";
import Modal from "rsuite/Modal";
import VisitUserPhotoReview from "./VisitUserPhotoReview";
import { SetState } from "../../../utils/models";
import { IconButton } from "rsuite";
import ArrowLeftLineIcon from "@rsuite/icons/ArrowLeftLine";
import { ISumaryReview } from "./VisitPhotoReview";

interface IVisitUserPhotoReviewModalProps {
  userData: ISumaryReview;
  setUserData: SetState;
}

const VisitUserPhotoReviewModal: FunctionComponent<
  IVisitUserPhotoReviewModalProps
> = (props) => {
  return (
    <>
      <Modal
        size="full"
        overflow={false}
        open={true}
        onClose={() => {
          props.setUserData(null);
        }}>
        <Modal.Header>
          <strong>{props.userData.userName}</strong>
        </Modal.Header>
        <Modal.Body>
          <VisitUserPhotoReview {...props.userData} />
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            appearance={"ghost"}
            icon={<ArrowLeftLineIcon />}
            onClick={() => {
              props.setUserData(null);
            }}>
            Zamknij
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VisitUserPhotoReviewModal;
