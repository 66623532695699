import React from "react";

const PowerBiEmbeddedReport: React.FunctionComponent = () => {
  // const url = "";

  return (
    <>
      <div>
        <div style={{ width: "100%", height: "calc(100vh - 100px)" }}>
          {/*    <iframe
            src={url}
            title="Power Bi Embedded"
            style={{
              border: "none",
              width: "100%",
              height: "100%",
            }}
            allowFullScreen
          />*/}
        </div>
      </div>
    </>
  );
};

export default PowerBiEmbeddedReport;
