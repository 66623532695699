import VisitPhotoReview from "../../views/visitReviews/components/VisitPhotoReview";
import { IRouteEntity } from "../../utils/models";
import VisitUserPhotoReview from "../../views/visitReviews/components/VisitUserPhotoReview";

const visitReviewRoutes: IRouteEntity[] = [
  {
    path: "/visit-reviews",
    breadcrumb: "Ocena zdjęć",
    Component: VisitPhotoReview,
  },
  {
    path: "/visit-reviews/:user",
    breadcrumb: "Raport użytkownika",
    Component: VisitUserPhotoReview,
  },
];

export default visitReviewRoutes;
