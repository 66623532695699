import {
  Checkbox,
  Col,
  DatePicker,
  InputNumber,
  SelectPicker,
  Toggle,
} from "rsuite";
import FormControlLabel from "rsuite/FormControlLabel";
import dayjs from "dayjs";
import FormErrorMessage from "rsuite/FormErrorMessage";
import React, { JSX } from "react";
import { SetState } from "../../../../utils/models";
import { IHourData, IScheduleModalData } from "../ScheduleModal";

import {
  COMMON_FOR_WEEK,
  getTimeToDate,
  hourDay,
  HOURS_EXPLICITLY,
  intervals,
  IScheduleModalBatchAddData,
  isNumberRange,
  THoursStrategy,
  TIME_STRING,
} from "./functions";
import { TModalType } from "../../Schedule";
import SpinnerSmall from "global/atoms/Spinner/SpinnerSmall";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import { WEEK_DAYS_OBJ } from "utils/dictionaries";

export const scheduleHoursSinglePicker = (
  isEnabled: boolean,
  errors: any,
  hoursDataSingle: IHourData | null,
  setHoursDataSingle: SetState<IHourData | null>
): JSX.Element => {
  const singleErrors = errors?.hours?.find(
    (_e) => _e?.day == 0 || _e?.day == undefined
  );

  return (
    <>
      <Col xs={24}>
        <FormControlLabel>
          <strong>Godziny</strong>
        </FormControlLabel>
      </Col>
      <Col xs={24} style={{ display: "flex", alignItems: "center" }}>
        <Col xs={4}>
          <Toggle
            size={"md"}
            checkedChildren={"Zakres"}
            unCheckedChildren={"Liczba"}
            checked={hoursDataSingle?.type === TIME_STRING}
            readOnly={!isEnabled}
            onChange={(checked) => {
              setHoursDataSingle((s) => ({
                ...s,
                type: checked ? TIME_STRING : HOURS_EXPLICITLY,
              }));
            }}
          />
        </Col>
        {hoursDataSingle?.type === TIME_STRING && (
          <>
            <Col xs={10}>
              <DatePicker
                size={"sm"}
                block
                format="HH:mm"
                cleanable={true}
                readOnly={!isEnabled}
                value={
                  hoursDataSingle?.start
                    ? dayjs(hoursDataSingle.start, "HH:mm").toDate()
                    : undefined
                }
                onChange={(value) => {
                  setHoursDataSingle((s) => {
                    if (!s) return s;
                    return {
                      ...s,
                      start: value ? dayjs(value).format("HH:mm") : undefined,
                    };
                  });
                }}
                ranges={[
                  {
                    label: "8:00",
                    value: dayjs().hour(8).minute(0).toDate(),
                  },
                  {
                    label: "10:00",
                    value: dayjs().hour(10).minute(0).toDate(),
                  },
                ]}
              />
              <FormErrorMessage show={singleErrors?.start}>
                {singleErrors?.start}
              </FormErrorMessage>
            </Col>
            <Col xs={10}>
              <DatePicker
                size={"sm"}
                block
                format="HH:mm"
                cleanable={true}
                readOnly={!isEnabled}
                value={
                  hoursDataSingle?.end
                    ? dayjs(hoursDataSingle.end, "HH:mm").toDate()
                    : undefined
                }
                onChange={(value) => {
                  setHoursDataSingle((s) => {
                    if (!s) return s;
                    return {
                      ...s,
                      end: value ? dayjs(value).format("HH:mm") : undefined,
                    };
                  });
                }}
                ranges={[
                  {
                    label: "16:00",
                    value: dayjs().hour(16).minute(0).toDate(),
                  },
                  {
                    label: "18:00",
                    value: dayjs().hour(18).minute(0).toDate(),
                  },
                ]}
              />
              <FormErrorMessage show={singleErrors?.end}>
                {singleErrors?.end}
              </FormErrorMessage>
            </Col>
          </>
        )}
        {hoursDataSingle?.type === HOURS_EXPLICITLY && (
          <Col xs={20}>
            <InputNumber
              size={"sm"}
              value={hoursDataSingle?.hours ?? undefined}
              step={0.01}
              min={0}
              max={100}
              readOnly={!isEnabled}
              onChange={(value) => {
                setHoursDataSingle((s) => {
                  if (!s) return s;
                  return {
                    ...s,
                    hours: value,
                  };
                });
              }}
            />
          </Col>
        )}
      </Col>
    </>
  );
};

export const scheduleHoursRecurringPicker = (
  isEnabled: boolean,

  errors: any,
  hoursDataSingle: IHourData | null,
  setHoursDataSingle: SetState<IHourData | null>,
  hoursData: Array<IHourData> | null,
  setHoursData: SetState<Array<IHourData> | null>,
  modalData: IScheduleModalData | IScheduleModalBatchAddData,
  hoursStrategy: THoursStrategy,
  modalDataType: TModalType,
  modalDataDate: Date | undefined,
  updateState: (
    value: Partial<IScheduleModalData | IScheduleModalBatchAddData>
  ) => void,
  dayPickerReadOnly?: boolean
) => {
  if (!modalData) return <SpinnerSmall />;

  const HourErrorMessage = (props) => {
    const { dayKey, dataKey } = props;

    const error = errors?.hours?.find((_e) => _e?.day == dayKey);
    if (!error) return <></>;
    const dayError = error?.[dataKey];
    if (dayError) {
      if (dayPickerReadOnly)
        return <FormErrorMessage show={dayError}>{dayError}</FormErrorMessage>;
      return (
        <span
          style={{
            color: "red",
            position: "absolute",
            top: "0px",
            right: "5px",
            zIndex: "50",
          }}>
          *
        </span>
      );
    }
    return <></>;
  };

  return (
    <>
      {isEnabled && hoursStrategy == COMMON_FOR_WEEK && (
        <>
          {scheduleHoursSinglePicker(
            isEnabled,
            errors,
            hoursDataSingle,
            setHoursDataSingle
          )}
          <SeparatorEmpty />
        </>
      )}
      <Col xs={12}>
        <FormControlLabel>
          <strong>Dni tygodnia</strong>
        </FormControlLabel>
      </Col>
      <Col xs={12}>
        <FormControlLabel>
          {" "}
          <strong>Godziny</strong>
        </FormControlLabel>
      </Col>

      {WEEK_DAYS_OBJ.map((wd) => (
        <>
          <Col xs={24} style={{ display: "flex", alignItems: "center" }}>
            <Col xs={10}>
              <Checkbox
                checked={(modalData.days ?? []).includes(wd.key)}
                key={`k-${wd.key}`}
                style={{
                  display: "block",
                  opacity: (modalData.days ?? []).includes(wd.key)
                    ? "1"
                    : "0.5",
                  padding: 0,
                }}
                readOnly={!isEnabled || dayPickerReadOnly}
                onChange={(value, checked) => {
                  const daysFiltered = (modalData?.days ?? []).filter(
                    (_d) => _d !== wd.key
                  );
                  updateState({
                    days: checked
                      ? daysFiltered.concat([wd.key])
                      : daysFiltered,
                  });
                }}>
                {" "}
                {wd.name}
              </Checkbox>
            </Col>
            {(modalData.days ?? []).includes(wd.key) && (
              <>
                <Col xs={4}>
                  <Toggle
                    size="md"
                    checkedChildren={"Zakres"}
                    unCheckedChildren={"Liczba"}
                    checked={!isNumberRange(wd.key, hoursData ?? [])}
                    disabled={!isEnabled || hoursStrategy == COMMON_FOR_WEEK}
                    onChange={(checked) => {
                      setHoursData((s) => {
                        const day = s?.find((d) => d.day == wd.key) ?? {
                          type: TIME_STRING,
                          day: wd.key,
                          hours: undefined,
                          start: undefined,
                          end: undefined,
                        };
                        day.type = checked ? TIME_STRING : HOURS_EXPLICITLY;
                        return [
                          ...(s?.filter((h) => h.day != wd.key) ?? []),
                          day,
                        ];
                      });
                    }}
                  />
                </Col>
                {!isNumberRange(wd.key, hoursData) && (
                  <>
                    <Col xs={5} style={{ position: "relative" }}>
                      <DatePicker
                        block
                        size={"sm"}
                        format="HH:mm"
                        cleanable={true}
                        disabled={
                          !isEnabled || hoursStrategy == COMMON_FOR_WEEK
                        }
                        value={getTimeToDate(wd.key, "start", hoursData)}
                        onChange={(value) => {
                          setHoursData((s) => {
                            const day = s?.find((d) => d.day == wd.key) ?? {
                              type: TIME_STRING,
                              day: wd.key,
                              hours: 0,
                              start: value
                                ? dayjs(value).format("HH:mm")
                                : undefined,
                            };
                            day.start = value
                              ? dayjs(value).format("HH:mm")
                              : undefined;
                            return [
                              ...(s?.filter((h) => h.day != wd.key) ?? []),
                              day,
                            ];
                          });
                        }}
                        ranges={[
                          {
                            label: "8:00",
                            value: dayjs().hour(8).minute(0).toDate(),
                          },
                          {
                            label: "10:00",
                            value: dayjs().hour(10).minute(0).toDate(),
                          },
                        ]}
                      />
                      <HourErrorMessage dayKey={wd.key} dataKey={"start"} />
                    </Col>
                    <Col xs={5} style={{ position: "relative" }}>
                      <DatePicker
                        block
                        size={"sm"}
                        format="HH:mm"
                        cleanable={true}
                        disabled={
                          !isEnabled || hoursStrategy == COMMON_FOR_WEEK
                        }
                        value={getTimeToDate(wd.key, "end", hoursData)}
                        onChange={(value) => {
                          setHoursData((s) => {
                            const day = s?.find((d) => d.day == wd.key) ?? {
                              type: TIME_STRING,
                              day: wd.key,
                              hours: 0,
                              end: value
                                ? dayjs(value).format("HH:mm")
                                : undefined,
                            };
                            day.end = value
                              ? dayjs(value).format("HH:mm")
                              : undefined;
                            return [
                              ...(s?.filter((h) => h.day != wd.key) ?? []),
                              day,
                            ];
                          });
                        }}
                        ranges={[
                          {
                            label: "16:00",
                            value: dayjs().hour(16).minute(0).toDate(),
                          },
                          {
                            label: "18:00",
                            value: dayjs().hour(18).minute(0).toDate(),
                          },
                        ]}
                      />
                      <HourErrorMessage dayKey={wd.key} dataKey={"end"} />
                    </Col>
                  </>
                )}
                {isNumberRange(wd.key, hoursData) && (
                  <Col xs={10}>
                    <InputNumber
                      size={"sm"}
                      value={hourDay(wd.key, hoursData)?.hours ?? undefined}
                      step={0.01}
                      min={0}
                      max={100}
                      disabled={!isEnabled || hoursStrategy == COMMON_FOR_WEEK}
                      onChange={(value: number | string) => {
                        setHoursData((s) => {
                          const day = s?.find((h) => h.day == wd.key) ?? {
                            day: wd.key,
                            hours: undefined,
                            start: undefined,
                            end: undefined,
                            type: HOURS_EXPLICITLY,
                          };
                          day.hours = value;
                          const days = s?.filter((h) => h.day != wd.key) ?? [];
                          return [...days, day];
                        });
                      }}
                    />
                  </Col>
                )}
              </>
            )}
          </Col>
        </>
      ))}
      <SeparatorEmpty />
      <Col xs={24}>
        <FormControlLabel>
          <strong>Częstotliwość</strong>
        </FormControlLabel>
        <SelectPicker
          data={Object.entries(intervals).map((v) => ({
            label: v[1],
            value: parseInt(v[0]),
          }))}
          block
          disabled={!isEnabled}
          value={modalData.type}
          cleanable={false}
          searchable={false}
          placeholder={"wybierz"}
          onChange={(value) => updateState({ type: Number(value) })}
        />
      </Col>

      <SeparatorEmpty size={1.5} />

      <Col xs={24}>
        <FormControlLabel>
          <strong>
            Harmonogram od - do&nbsp;&nbsp;
            <Toggle
              size="md"
              checkedChildren={"∞"}
              unCheckedChildren={"data końcowa"}
              checked={modalData.endDate === null}
              disabled={!isEnabled}
              onChange={(val) => {
                updateState({
                  endDate: !val ? undefined : null,
                });
              }}
            />
          </strong>
        </FormControlLabel>
      </Col>
      <Col xs={24}>
        <Col xs={12}>
          <DatePicker
            block
            placement={"topStart"}
            cleanable={false}
            isoWeek
            oneTap
            disabled={!isEnabled || modalDataType === "modify"}
            shouldDisableDate={(date) => dayjs(date).isBefore(dayjs())}
            onChange={(date) => updateState({ startDate: date })}
            value={modalData.startDate}
          />
        </Col>
        <Col xs={12}>
          {modalData.endDate !== null && (
            <DatePicker
              block
              placement={"topStart"}
              isoWeek
              oneTap
              cleanable={false}
              disabled={!isEnabled}
              shouldDisableDate={(date) =>
                dayjs(date).isBefore(modalData.startDate ?? dayjs())
              }
              onChange={(date) => updateState({ endDate: date })}
              value={modalData.endDate}
            />
          )}
          {modalData.endDate === null && (
            <div
              style={{
                textAlign: "center",
                marginTop: "8px",
                color: "#a2a2a2",
              }}>
              Brak daty końcowej
            </div>
          )}
        </Col>
      </Col>
    </>
  );
};

export default scheduleHoursSinglePicker;
