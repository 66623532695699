import React, {
  FC,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import styles from "./styles.module.scss";
import { FCC, IPhotoQuestionValue } from "../../../../../../../utils/models";
import Instances from "../../../../../../../utils/connections/instances";
import {
  Button,
  Form,
  IconButton,
  Input,
  Message,
  Rate,
  Tooltip,
  Uploader,
  Whisper,
} from "rsuite";
import LocationIcon from "@rsuite/icons/Location";
import FileUploadIcon from "@rsuite/icons/FileUpload";
import { FileType } from "rsuite/esm/Uploader/Uploader";
import { deepClone, getBaseApiUrl, rotateBase64Img } from "utils/helpers";

import Modal from "rsuite/Modal";
import ReloadIcon from "@rsuite/icons/Reload";
import AngleRightIcon from "@rsuite/icons/legacy/AngleRight";
import AngleLeftIcon from "@rsuite/icons/legacy/AngleLeft";
import ResizeIcon from "@rsuite/icons/Resize";
import { usePhotoContext, useStaticContext } from "../VisitHelpers";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import { FeedbackStateContext } from "./QuestionBody";
import toastNotification, {
  ToastTypes,
} from "../../../../../../../global/ToastNotification";
import { FormElementVisitEdit } from "../../../../../../../utils/FormElement";
import FeedbackFormDrawer from "./FeedbackFormDrawer";
import { INTEGER_QUESTION } from "../QuestionTypeConsts";

interface IPhotoQuestion {
  refQuestionId: string;
  isDisabled: boolean;
  isReadOnly?: boolean;
}

export interface IPhotoQuestionValueExtended
  extends IPhotoQuestionValue,
    FileType {
  refQuestionId: string;
}

// unattached from visit form - because additional data attached after "upload" - which result in state issues
const PhotoQuestion: FunctionComponent<IPhotoQuestion> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [fullSize, setFullSize] = useState(false);

  const {
    feedbackForm,
    formsFeedback,
    hiddenElements,
    setFeedbackForm,
    setCommonReview,
  } = useContext(FeedbackStateContext) || {};
  const [fileToShow, setFileToShow] =
    useState<IPhotoQuestionValueExtended | null>();
  const [imageData, setImageData] = useState<string | null>(null);
  const [imageRotation, setImageRotation] = useState<number>(0);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const { visitPhotos, setVisitPhotos } = usePhotoContext();
  const { questionsFlatState } = useStaticContext();

  const visitQuestionPhotos = visitPhotos.filter(
    (vp) => vp.refQuestionId === props.refQuestionId
  );

  if (!visitQuestionPhotos) return <></>;

  const getLocationCoords = (
    callback: (position: GeolocationPosition | null) => void
  ) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => callback(position),
        () => callback(null)
      );
    } else {
      callback(null);
    }
  };

  const getImage = () => {
    setLoadingImage(true);
    const blobToBase64 = (blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
        reader.onerror = reject;

        reader.readAsDataURL(blob);
      });
    };
    if (fileToShow?.refQuestionAnswerId) {
      Instances.getQuestionImageBase64Data(
        fileToShow.refQuestionAnswerId,
        imageRotation
      ).then((blobFile: Blob) => {
        blobToBase64(blobFile).then((base64Data) => {
          setImageData(base64Data as string);
          setLoadingImage(false);
        });
      });
    }
  };
  const readAsDataURL = (
    file: FileType
  ): Promise<{ value: string; fileKey: string | number }> => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onerror = reject;
      fr.onload = ({ target }) => {
        resolve({
          value: target?.result?.toString() ?? "",
          fileKey: file.fileKey ?? "",
        });
      };
      if (file.blobFile) fr.readAsDataURL(file.blobFile);
    });
  };

  // added / removed
  const uploader = (
    _fileList: Array<FileType | IPhotoQuestionValueExtended>
  ) => {
    const actionRemove = _fileList.length < visitQuestionPhotos.length;
    if (actionRemove) {
      setVisitPhotos([
        // all photos NOT from this question
        ...visitPhotos.filter((vp) => vp.refQuestionId !== props.refQuestionId),
        // this questions photos that were not returned by the uploader
        ...(_fileList as IPhotoQuestionValueExtended[]),
      ]);
    } else {
      // new photo(s) added
      // get base64 from blobs
      const promises: ReturnType<typeof readAsDataURL>[] = [];
      const newPhotos = _fileList.filter(
        (f) => !("refQuestionAnswerId" in f)
      ) as FileType[];
      newPhotos.forEach((file) => {
        promises.push(readAsDataURL(file));
      });
      const newPhotosParsed = [] as IPhotoQuestionValueExtended[];

      // update / extend value for new photos
      Promise.all(promises).then((values) => {
        values.forEach((value) => {
          const fl = newPhotos.find((np) => np.fileKey === value.fileKey);
          if (fl) {
            newPhotosParsed.push({
              value: value.value,
              fileKey: fl.fileKey ?? "-",
              refQuestionAnswerId: "",
              refQuestionId: props.refQuestionId,
              comment: "",
              status: "finished",
              lat: null,
              lon: null,
              rate: null,
            });
          }
        });

        // get and assign coords to new photos
        getLocationCoords((position: GeolocationPosition | null) => {
          if (position !== null) {
            const coords: GeolocationCoordinates | null =
              position?.coords ?? null;
            const lat = coords?.latitude.toString() ?? null;
            const lon = coords?.longitude.toString() ?? null;

            values.forEach((value) => {
              const fl_index = newPhotosParsed.findIndex(
                (f) => f.fileKey === value.fileKey
              );
              if (fl_index < 0) {
                console.log("index not found", value.fileKey, newPhotosParsed);
              } else {
                newPhotosParsed[fl_index].lat = lat;
                newPhotosParsed[fl_index].lon = lon;
              }
            });
          }

          setVisitPhotos([...visitPhotos, ...newPhotosParsed]);
        });
      });
    }
  };
  const handleClose = () => {
    setShowModal(false);
    setFileToShow(null);
    setImageData(null);
    setImageRotation(0);
  };

  const handleRotatedImageSave = () => {
    const cloned = deepClone(visitPhotos);
    const index = cloned.findIndex((c) => c.fileKey === fileToShow?.fileKey);
    cloned[index].value = imageData as string;
    setVisitPhotos(cloned);
    if (fileToShow?.refQuestionAnswerId) {
      Instances.putRotatedImage(fileToShow.refQuestionAnswerId, imageRotation)
        .then(() => {
          setImageRotation(0);
        })
        .catch(() => {
          toastNotification(
            ToastTypes.error,
            "Zapisanie pliku zdjęciowego nie powiodło się"
          );
        });
    }
    handleClose();
  };

  useEffect(() => {
    getImage();
  }, [fileToShow, imageRotation]);

  const rotateImage = (isClockVise) => {
    const image: HTMLImageElement | null = document.getElementById(
      "active-image"
    ) as HTMLImageElement;

    setImageRotation((d) => (d + (isClockVise ? 90 : -90)) % 360);

    if (fileToShow?.refQuestionAnswerId === "") {
      const rotated = rotateBase64Img(image.src, isClockVise);
      setImageData(rotated);
    }
  };

  const RenderThumbnail: FCC<{
    file: IPhotoQuestionValueExtended;
    thumbnail?: React.ReactNode;
  }> = ({ file, thumbnail }) => {
    const _thumbnail =
      "value" in file && file.value ? (
        <img
          alt={file.name}
          src={file.value}
          style={{
            boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
            borderRadius: "5px",
            height: "100%",
          }}
        />
      ) : (
        thumbnail
      );

    const feedback = formsFeedback?.[file.refQuestionAnswerId]?.find(
      (f) => f.feedbackType == "KPI"
    );

    const RenderFeedbackRate = () => {
      let value = "";
      if (feedback) {
        const rate = feedback.feedbackDetails.find(
          (fd) => fd.feedbackQuestion.questionType === "INTEGER_QUESTION"
        );
        value = rate?.values?.[0]?.value ?? "0";
      }
      return (
        <>
          <div className={styles.rateHover}>
            <Rate
              value={parseFloat(value)}
              max={5}
              cleanable={false}
              size={"xs"}
              style={{
                position: "relative",
                right: "3px",
                color: feedback?.isChanged ? "green" : "#E09616",
                fontSize: "1em",
                display: "flex",
                flexDirection: "column-reverse",
                alignItems: "space-between",
              }}
              onClick={() => {
                setFeedbackForm?.(feedback ?? undefined);
                setCommonReview?.(false);
              }}
              onChange={(rate) => {
                if (!feedback) return;
                feedback.isChanged = true;
                const intQuestionIndex = feedback.feedbackDetails.findIndex(
                  (q) => q.feedbackQuestion.questionType == INTEGER_QUESTION
                );
                try {
                  const _rate = Math.ceil(rate).toString();
                  feedback.feedbackDetails[intQuestionIndex].values = [
                    {
                      value: _rate,
                      label: _rate,
                      refQuestionAnswerId:
                        feedback.feedbackDetails[intQuestionIndex]
                          .feedbackQuestion.id,
                    },
                  ];
                } catch (e) {
                  console.log(e);
                }
                setFeedbackForm?.(feedback);
                setCommonReview?.(false);
              }}
            />
          </div>
        </>
      );
    };

    return (
      <>
        <div
          style={{
            position: "relative",
            overflow: "hidden",
            border: "1px solid silver",
            borderLeftColor:
              feedbackForm?.feedbackType == "KPI" &&
              file.refQuestionAnswerId == feedbackForm.idVisitQuestion
                ? "green"
                : "silver",
            borderLeftWidth:
              feedbackForm?.feedbackType == "KPI" &&
              file.refQuestionAnswerId == feedbackForm.idVisitQuestion
                ? "3px"
                : "1px",
            boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
            borderRadius: "5px",
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            height: "220px",
            width: "220px",
          }}>
          {file && "lat" in file && (!file.lat || !file.lon) && (
            <Whisper speaker={<Tooltip>Brak danych z GPS!</Tooltip>}>
              <LocationIcon
                color={"#ff0033"}
                style={{
                  fontSize: "1.25em",
                  position: "absolute",
                  top: "4px",
                  left: "4px",
                  opacity: ".3",
                }}
              />
            </Whisper>
          )}
          {!props.isDisabled && !props.isReadOnly && (
            <Whisper speaker={<Tooltip>Usuń zdjęcie</Tooltip>}>
              <CloseOutlineIcon
                color={"#E09616"}
                onClick={() => {
                  const cloned = deepClone(visitPhotos);
                  const index = cloned.findIndex(
                    (c) => c.fileKey === file.fileKey
                  );
                  cloned.splice(index, 1);
                  setVisitPhotos(cloned);
                }}
                style={{
                  cursor: "pointer",
                  fontSize: "1.25em",
                  position: "absolute",
                  top: "4px",
                  right: "4px",
                }}
              />
            </Whisper>
          )}
          {file &&
            file.refQuestionAnswerId &&
            !hiddenElements?.includes(
              FormElementVisitEdit.SEND_KPI_SINGLE_FEEDBACK
            ) && (
              <>
                <RenderFeedbackRate />
              </>
            )}
          {file && "refQuestionAnswerId" in file ? (
            <>
              <div
                style={{
                  overflow: "hidden",
                  height: "200px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowModal(true);
                  setFileToShow(file);
                  setImageData(file.value);
                }}
                className={styles.innerLink}>
                {_thumbnail}
              </div>
            </>
          ) : (
            _thumbnail
          )}
        </div>
      </>
    );
  };

  const RenderFileInfo: FC<{
    file: FileType | IPhotoQuestionValueExtended;
  }> = ({ file }) => {
    if ("refQuestionAnswerId" in file) {
      return (
        <>
          <Form.Group>
            <Form.Control
              name={`${props.refQuestionId}.description.${file.fileKey}`}
              size="xs"
              placeholder="opis zdjęcia..."
              accepter={Input}
              style={{ width: "100%" }}
              defaultValue={file.comment ?? undefined}
              onChange={(value: string | undefined) => {
                const cloned = deepClone(visitPhotos);
                cloned[
                  cloned.findIndex((c) => c.fileKey === file.fileKey)
                ].comment = value ?? "";
                setVisitPhotos(cloned);
              }}
            />
          </Form.Group>
        </>
      );
    }
    return <></>;
  };
  const questionName = (refQuestionId: string | undefined) => {
    const question = Object.values(questionsFlatState).find(
      (q) => q.refQuestionId === refQuestionId
    );
    return question?.questionName ?? "";
  };
  const questionPhotosCount = (refQuestionId: string | undefined) =>
    visitPhotos.filter((vp) => vp.refQuestionId === refQuestionId)?.length ??
    "";

  return (
    <>
      {!imageData && (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Form.Control
              // name={props.refQuestionId}
              name={"__skip_me"}
              accepter={Uploader}
              multiple
              classPrefix={"photo-question-uploader"}
              size={"sm"}
              listType="picture-text"
              disabled={props.isDisabled}
              plaintext={props.isReadOnly}
              readOnly={props.isReadOnly}
              accept={"image/*"}
              onChange={uploader}
              autoUpload={false}
              fileListVisible={false}
              fileList={visitQuestionPhotos}
              removable={!props.isDisabled}
              //renderFileInfo={RenderFileInfo}
              //renderThumbnail={RenderThumbnail}
              action={""}>
              <IconButton
                style={{ display: props.isDisabled ? "none" : "inherit" }}
                appearance={"ghost"}
                disabled={props.isDisabled}
                icon={
                  <FileUploadIcon
                    color={`${props.isDisabled ? "#eeeee" : "#ffaf38"}`}
                  />
                }>
                Dodaj zdjęcia
              </IconButton>
            </Form.Control>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginTop: "10px",
                gap: "20px",
                maxHeight: "270px",
                overflow: "auto",
              }}>
              {visitQuestionPhotos &&
                visitQuestionPhotos.map((photo) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "220px",
                          justifyContent: "flex-start",
                        }}>
                        <RenderThumbnail file={photo} />
                        <RenderFileInfo file={photo} />
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </>
      )}
      {!hiddenElements?.includes(
        FormElementVisitEdit.SEND_KPI_SINGLE_FEEDBACK
      ) && <FeedbackFormDrawer forceUpdate={false} />}
      <Modal
        open={showModal}
        onClose={handleClose}
        size={fullSize ? "full" : "lg"}>
        <Modal.Header>
          <Modal.Title>
            Pytanie zdjęciowe - podgląd {fileToShow?.name}{" "}
            {fileToShow?.order &&
              `${fileToShow?.order} z ${questionPhotosCount(
                fileToShow.refQuestionId
              )}`}
          </Modal.Title>
          <div
            style={{
              padding: "5px",
              zIndex: "100",
              border: "1px solid silver",
              backgroundColor: "#FAFAFA88",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              width: "200px",
              height: "40px",
              position: "absolute",
              top: "5px",
              right: "50%",
              transform: "translateX(100px)",
            }}>
            <IconButton
              title={"Obróć w lewo"}
              disabled={loadingImage && fileToShow?.refQuestionAnswerId != ""}
              onClick={rotateImage.bind(null, false)}
              style={{ transform: "rotateY(180deg)" }}
              appearance={"ghost"}
              icon={<ReloadIcon />}
            />
            <IconButton
              title={"Obróć w prawo"}
              disabled={loadingImage && fileToShow?.refQuestionAnswerId != ""}
              onClick={rotateImage.bind(null, true)}
              appearance={"ghost"}
              icon={<ReloadIcon />}
            />
            <IconButton
              title={"Pełny rozmiar"}
              onClick={() => {
                setFullSize(!fullSize);
              }}
              appearance={fullSize ? "ghost" : "default"}
              icon={<ResizeIcon />}
            />
            <IconButton
              title={"Pobierz zdjęcie"}
              as={"a"}
              href={`${getBaseApiUrl()}/public/question-answer-photo/${fileToShow?.refQuestionAnswerId}`}
              appearance={"default"}
              icon={<FileDownloadIcon />}
            />
          </div>
        </Modal.Header>
        <Modal.Body style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              left: "0px",
              top: "45%",
              zIndex: "500",
            }}>
            <AngleRightIcon
              style={{
                fontSize: "3em",
                cursor: "pointer",
                position: "absolute",
                right: "20px",
                top: "50%",
                color: "rgb(224, 150, 22)",
              }}
              onClick={() => {
                const index = visitPhotos.findIndex(
                  (vp) => vp.fileKey === fileToShow?.fileKey
                );
                if (index < visitPhotos.length - 1) {
                  setFileToShow(visitPhotos[index + 1]);
                  setImageData(visitPhotos[index + 1].value);
                } else {
                  setFileToShow(visitPhotos[0]);
                  setImageData(visitPhotos[0].value);
                }
              }}
            />
            <AngleLeftIcon
              style={{
                fontSize: "3em",
                cursor: "pointer",
                position: "absolute",
                left: "20px",
                top: "50%",
                color: "rgb(224, 150, 22)",
              }}
              onClick={() => {
                const index = visitPhotos.findIndex(
                  (vp) => vp.fileKey === fileToShow?.fileKey
                );
                if (index > 0) {
                  setFileToShow(visitPhotos[index - 1]);
                  setImageData(visitPhotos[index - 1].value);
                } else {
                  setFileToShow(visitPhotos[visitPhotos.length - 1]);
                  setImageData(visitPhotos[visitPhotos.length - 1].value);
                }
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
            }}>
            {!imageData && !fileToShow?.value && (
              <Message header={"Błąd ładowania"} type={"warning"} showIcon>
                Nie udało się załadować zdjęcia{" "}
              </Message>
            )}
            {(imageData || fileToShow?.value) && (
              <img
                alt=""
                id="active-image"
                style={{
                  width: fullSize ? "95%" : "500px",
                  height: fullSize ? "auto" : "100%",
                }}
                src={imageData ?? fileToShow?.value}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              position: "absolute",
              left: "10px",
              bottom: "10px",
              fontSize: "14px",
            }}>
            {questionName(fileToShow?.refQuestionId)}
          </div>
          {!props.isDisabled &&
            !props.isReadOnly &&
            (fileToShow?.refQuestionAnswerId === "" || imageRotation !== 0) && (
              <Button onClick={handleRotatedImageSave} appearance={"ghost"}>
                Zapisz
              </Button>
            )}
          <Button onClick={handleClose} appearance={"subtle"}>
            Zamknij
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PhotoQuestion;
