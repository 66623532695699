import React, {
  CSSProperties,
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import styles from "./styles.module.scss";
import { Loader, Tooltip, Whisper } from "rsuite";
import { TypeDoubleSidePickerItems } from "../../../utils/types";
import Section from "../section/Section";
import _ from "lodash";
import DraggableIcon from "@rsuite/icons/Dragable";
import ItemsThinList from "./ItemsThinList";

export interface IItem {
  id: string;
  name: string;
}
export interface IItemBody {
  item: IItem;
  checked: Array<string>;
  handleToggleCheckbox: (itemId: string) => void;
}
export interface IFilterForm {
  [a: string]: {
    filterLabel: string;
    filterId: string;
    parentFilterId?: string | Array<string>;
    options: Array<{ id: string; name: string }>;
  };
}

export interface IDoubleSidePicker {
  heading?: string;
  emptyMessageAssigned: ReactElement | string;
  emptyMessageNotAssigned: string;
  assigned: TypeDoubleSidePickerItems;
  unassigned: TypeDoubleSidePickerItems;
  onChange: (
    assignedIds: Array<string>,
    unAssignedIds: Array<string>,
    callback?: () => void
  ) => void;
  filterForm?: IFilterForm;
  style?: CSSProperties;
  pickedItemCallback?: (itemId: string) => void;
  clearPickedItemTrigger?: number;
  disabled?: boolean;
  loading?: boolean;
  actionOnAssigned?: (event) => void;
  filterComponent?: React.ComponentType<any>;
  itemDataComponent: React.ComponentType<IItemBody>;
}

export interface IChecked {
  assigned: Array<string>;
  unassigned: Array<string>;
}

export const checkedInitValue = {
  assigned: [],
  unassigned: [],
};

const DoubleSideThinPicker: FunctionComponent<IDoubleSidePicker> = (props) => {
  const [processing, setProcessing] = useState(false);
  const [checked, setChecked] = useState<IChecked>(checkedInitValue);
  const [pickedId, setPickedId] = useState<string | null>(null);
  const data = {
    assigned: props.assigned,
    unassigned: props.unassigned,
  };
  const [triggerFilter, setTriggerFilter] = useState(0);

  useEffect(() => {
    if (pickedId && props.pickedItemCallback)
      props.pickedItemCallback(pickedId);
  }, [pickedId]);

  if (props.clearPickedItemTrigger !== undefined) {
    useEffect(() => {
      // @ts-ignore
      if (props.clearPickedItemTrigger > 0) {
        setPickedId(null);
      }
    }, [props.clearPickedItemTrigger]);
  }

  const handleToggleAssigned = (itemId: string) => {
    if (checked.assigned.includes(itemId)) {
      setChecked({
        ...checked,
        assigned: checked.assigned.filter((item) => item !== itemId),
      });
    } else {
      setChecked({ ...checked, assigned: [...checked.assigned, itemId] });
    }
  };

  const handleToggleAllAssigned = (ids: Array<string>) => {
    if (checked.assigned.length) {
      setChecked({
        ...checked,
        assigned: [],
      });
    } else {
      setChecked({ ...checked, assigned: ids });
    }
  };

  const handleToggleUnassigned = (itemId: string) => {
    if (checked.unassigned.includes(itemId)) {
      setChecked({
        ...checked,
        unassigned: checked.unassigned.filter((item) => item !== itemId),
      });
    } else {
      setChecked({ ...checked, unassigned: [...checked.unassigned, itemId] });
    }
  };

  const handleToggleAllUnassigned = (ids: Array<string>) => {
    if (checked.unassigned.length) {
      setChecked({
        ...checked,
        unassigned: [],
      });
    } else {
      setChecked({ ...checked, unassigned: ids });
    }
  };

  const assignmentChange = () => {
    setProcessing(true);

    setTimeout(() => {
      const allAssignedIds = [
        ...props.assigned
          .filter((i) => !checked.assigned.includes(i.id))
          .map((i) => i.id),
        ...props.unassigned
          .filter((i) => checked.unassigned.includes(i.id))
          .map((i) => i.id),
      ];
      const allUnAssignedIds = [
        ...props.unassigned
          .filter((i) => !checked.unassigned.includes(i.id))
          .map((i) => i.id),
        ...props.assigned
          .filter((i) => checked.assigned.includes(i.id))
          .map((i) => i.id),
      ];

      props.onChange(_.uniq(allAssignedIds), _.uniq(allUnAssignedIds), () => {
        setProcessing(false);
      });
      setChecked(checkedInitValue);
      setTriggerFilter(Date.now());
      setProcessing(false);
    }, 1);
  };

  const handleSortChange = (assignedSortedData: TypeDoubleSidePickerItems) => {
    props.onChange(
      // @ts-ignore
      _.uniq(assignedSortedData.map((item) => item.id)),
      // @ts-ignore
      _.uniq(props.unassigned.map((item) => item.id))
    );
  };

  return (
    <>
      <div style={props.style ?? {}}>
        <Section title={`${props.heading ?? ""}`} titleSize={"1.1em"}>
          <div className={styles.grid}>
            <ItemsThinList
              actionOnAssigned={props.actionOnAssigned}
              handleToggleCheckbox={handleToggleAssigned}
              handleToggleMainCheckbox={handleToggleAllAssigned}
              checked={checked.assigned}
              setChecked={setChecked}
              data={data}
              filterForm={props.filterForm}
              filterComponent={props.filterComponent}
              itemDataComponent={props.itemDataComponent}
              emptyMessage={props.emptyMessageAssigned}
              pickedId={pickedId}
              setPickedId={setPickedId}
              dataType={"assigned"}
              triggerFilter={triggerFilter}
              onSortChange={handleSortChange}
            />

            {!props.disabled && (
              <>
                <div className={styles.DSPickerSelectorNav}>
                  <button
                    style={{ backgroundColor: "transparent" }}
                    type={"button"}
                    onClick={assignmentChange}
                    disabled={
                      (!checked.assigned.length &&
                        !checked.unassigned.length) ||
                      processing ||
                      props.loading
                    }>
                    {processing || props.loading ? (
                      <Loader />
                    ) : (
                      <Whisper
                        placement="top"
                        trigger="hover"
                        disabled={
                          !checked.assigned.length && !checked.unassigned.length
                        }
                        speaker={<Tooltip>Przesuń zaznaczone</Tooltip>}>
                        <DraggableIcon
                          style={{
                            transform: "rotate(90deg)",
                            fontSize: "2em",
                            color: "#ffaf38",
                          }}
                        />
                      </Whisper>
                    )}
                  </button>
                </div>

                <ItemsThinList
                  handleToggleCheckbox={handleToggleUnassigned}
                  handleToggleMainCheckbox={handleToggleAllUnassigned}
                  checked={checked.unassigned}
                  setChecked={setChecked}
                  data={data}
                  emptyMessage={props.emptyMessageNotAssigned}
                  pickedId={pickedId}
                  setPickedId={setPickedId}
                  dataType={"unassigned"}
                  triggerFilter={triggerFilter}
                  filterForm={props.filterForm}
                  filterComponent={props.filterComponent}
                  itemDataComponent={props.itemDataComponent}
                />
              </>
            )}
          </div>
        </Section>
      </div>
    </>
  );
};

export default DoubleSideThinPicker;
