import AxiosHelper, { default as axios } from "../AxiosHelper";
import { getAuthHeader, getBaseApiUrl, getOrganizationId } from "../helpers";
import {
  ICreateNotification,
  IEditNotification,
  IGetNotifications,
  IMailerRequest,
} from "../models";
import { AxiosPromise } from "axios";

const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/notifications`;
const baseMailerUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/mailers`;

const NotificationsConnection = {
  getNotifications: (data: IGetNotifications): Promise<any> => {
    // console.log(data, getAuthHeader());
    return axios.post(`${baseUrl}/list`, data, {
      headers: getAuthHeader(),
    });
  },
  getNotification: (id: string): Promise<any> => {
    return axios.get(`${baseUrl}/${id}`, {
      headers: getAuthHeader(),
    });
  },
  createNotification: (data: ICreateNotification): Promise<any> => {
    return axios.post(`${baseUrl}`, data, {
      headers: getAuthHeader(),
    });
  },
  editNotification: (data: IEditNotification): Promise<any> => {
    return axios.put(`${baseUrl}`, data, {
      headers: getAuthHeader(),
    });
  },
  delete: (notificationId: string): AxiosPromise<any> => {
    return AxiosHelper.delete(`${baseUrl}/${notificationId}`);
  },
  getFilterForm: (): Promise<any> => {
    return axios.get(`${baseUrl}/filter/form`);
  },
  getForm: (): Promise<any> => {
    return axios.get(`${baseUrl}/form`);
  },

  getUsersFilterForm: (isEnableLocation: boolean): Promise<any> => {
    return axios.post(`${baseUrl}/form/filter-users`, {
      isEnableLocation: isEnableLocation,
    });
  },
  getFormRightsGet: (): Promise<any> => {
    return axios.get(
      `${baseUrl}/rights-get/all`,
      undefined,
      "notification-rights-get"
    );
  },

  downloadFileUrl: (id: string, type: "iconPath" | "attachment"): string => {
    const user = localStorage.getItem("user");
    if (!user) return "";
    return `${baseUrl}/files/${id}/${
      type === "iconPath" ? "icon" : "attachment"
    }?access_token=${JSON.parse(user ?? "").token}`;
  },
  getFileBlob: (id: string, type: "iconPath" | "attachment") => {
    return axios.get(
      `${baseUrl}/files/${id}/${type === "iconPath" ? "icon" : "attachment"}`,
      {
        headers: getAuthHeader(),
        responseType: "blob",
      }
    );
  },
  uploadFileNotification: (data) => {
    return axios.post(`${baseUrl}/imports`, data);
  },
  sendReportNotification: (notificationId) => {
    return axios.get(`${baseUrl}/${notificationId}/send_report`, {
      headers: getAuthHeader(),
      responseType: "blob",
    });
  },

  getMailerForm: (): AxiosPromise<any> => {
    return AxiosHelper.get(`${baseMailerUrl}/filter/form`);
  },
  getMailerList: (requestData: IMailerRequest): AxiosPromise<any> => {
    return AxiosHelper.post(`${baseMailerUrl}/list`, requestData);
  },
  getAttachmentsBlob: (mailerId: string): AxiosPromise<any> => {
    return AxiosHelper.getBlob(`${baseMailerUrl}/${mailerId}/attachments`);
  },
  sendSelected: (mailerIds: Array<string>): AxiosPromise<any> => {
    return AxiosHelper.patch(`${baseMailerUrl}/send`, {
      ids: mailerIds.map((mid) => ({ id: mid })),
    });
  },
};

export default NotificationsConnection;
