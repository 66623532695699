import { IRouteEntity } from "../../utils/models";
import PowerBiEmbeddedReport from "../../views/powerBi/PowerBiEmbeddedReport";

const powerBiReportsRoutes: IRouteEntity[] = [
  {
    path: "/reports-power-bi",
    breadcrumb: "Power Bi",
    Component: PowerBiEmbeddedReport,
  },
  {
    path: "/reports-power-bi/embedded",
    breadcrumb: "Raporty",
    Component: PowerBiEmbeddedReport,
  },
];

export default powerBiReportsRoutes;
