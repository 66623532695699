import React, { FunctionComponent, useEffect, useState } from "react";
import SLAReviewForm from "./Form/SLAReviewForm";
import KPIReviewForm from "./Form/KPIReviewForm";
import Section from "../../../../global/atoms/section/Section";
import Textarea from "../../../../global/atoms/Textarea";
import styles from "../styles.module.scss";
import {
  IFeedbackDetails,
  IPhotoReviewErrors,
  IPhotoReviewFeedback,
} from "../../../../utils/models";
import VisitReviewsConnection, {
  IPhotoReviewFormsSingular,
} from "utils/connections/visitReviews";
import { useDispatch, useSelector } from "react-redux";
import { IPhotoReviewData, IRoot } from "redux/models";
import { SET_PHOTO_REVIEW } from "redux/actions";
import { Button, Message } from "rsuite";
import {
  getKPIFeedback,
  getKPIReason,
  getKPIValueFromBulk,
} from "views/visitReviews/helper";
import { deepClone } from "utils/helpers";
import _ from "lodash";

interface IReviewForm {
  forms: IPhotoReviewFormsSingular;
  errors: IPhotoReviewErrors;
  reload: () => void;
}

export const t = (key: string): string => {
  const map = {
    //SLA:"SLA",
    //KPI:"KPI",
    COMPLETION_CHECK: "zgodność z poleceniem",
  };
  return map?.[key] ?? key;
};
const MyReviewForm: FunctionComponent<IReviewForm> = (props) => {
  const dispatch = useDispatch();
  const photoReviewState = useSelector((state: IRoot) => state.photoReview);
  const [note, setNote] = useState<string>("");
  const formsArr = Object.values(props.forms ?? {});
  const isBulk = (): boolean => formsArr.length > 1;

  const undoReview = () => {
    if (crossFeedbacksData) {
      VisitReviewsConnection.removeFeedback(formsArr.map((f) => f.id)).then(
        () => {
          props.reload();
        }
      );
    }
  };

  // first feedback data is used as a base for all feedbacks.
  // It is the same for all feedbacks since in bulk value is the same for each.
  const getCrossFeedbacksData = (): null | IPhotoReviewFeedback => {
    if (formsArr.length === 0) return null;
    if (!isBulk()) return formsArr[0];

    let bulkIdenticalValues = true;

    const isFeedbackDetailsEqual = (
      a: IFeedbackDetails[],
      b: IFeedbackDetails[]
    ): boolean => {
      let equal = true;

      a.forEach((a_arr) => {
        b.forEach((b_arr) => {
          if (a_arr.feedbackQuestion.id === b_arr.feedbackQuestion.id) {
            // no value coverage
            if (
              !_.isEqual(
                a_arr.values?.map((v) => v.value).sort(),
                b_arr.values?.map((v) => v.value).sort()
              )
            ) {
              equal = false;
            }
          }
        });
      });

      return equal;
    };

    // loop thru each review
    formsArr.forEach((v) => {
      formsArr.forEach((v2) => {
        if (
          !isFeedbackDetailsEqual(v.feedbackDetails, v2.feedbackDetails) ||
          v.note !== v2.note
        ) {
          bulkIdenticalValues = false;
        }
      });
    });

    if (bulkIdenticalValues) return formsArr[0];
    return null;
  };
  const crossFeedbacksData = getCrossFeedbacksData();

  useEffect(() => {
    if (isBulk()) return; // no comment in bulk mode
    setNote(crossFeedbacksData?.note ?? "");
  }, []);

  useEffect(() => {
    if (isBulk() || !photoReviewState) return;
    if (note === photoReviewState.note) return;
    dispatch({
      type: SET_PHOTO_REVIEW,
      payload: {
        ...photoReviewState,
        note: note,
      },
    });
  }, [note]);

  const handleReviewChange = (
    type: "SLA" | "KPI",
    feedbackDetails: IFeedbackDetails[]
  ) => {
    const review: IPhotoReviewData = {
      feedbackType: type,
      refAnswerIds: Object.keys(props.forms ?? {}),
      note: note,
      feedbackDetails: feedbackDetails,
    };
    dispatch({ type: SET_PHOTO_REVIEW, payload: review });
  };

  if (formsArr.length === 0) return <></>;
  if (!crossFeedbacksData)
    return (
      <>
        <Message style={{ marginTop: "15px" }}>
          Brak możliwości oceny zbiorczej z uwagi na różnice występujące w
          ocenach jednostkowych
          <br />
          Aby wyświetlić oceny jednostkowe kliknij &quot;Wyświetl wszystkie
          oceny&quot; poniżej
        </Message>
      </>
    );

  return (
    <div>
      <Section
        key={`myReviewFormSection`}
        className={styles.reviewSection}
        title={`Twoja ocena${crossFeedbacksData.id !== "" ? " (edycja)" : ""}`}
        style={{ marginTop: "25px", borderColor: "rgba(255, 175, 56, 0.9)" }}>
        <div
          className={`${styles.reviewTypeWatermark} ${
            crossFeedbacksData.id !== "" && styles.active
          }`}>
          {t(crossFeedbacksData.feedbackType)}
        </div>

        {crossFeedbacksData.feedbackType === "SLA" && props.forms && (
          <SLAReviewForm
            isEdit={crossFeedbacksData.id !== ""}
            feedbackDetails={deepClone(crossFeedbacksData.feedbackDetails)}
            errors={props.errors.SLA}
            onChange={(feedbackDetails: IFeedbackDetails[]) =>
              handleReviewChange("SLA", feedbackDetails)
            }
          />
        )}

        {crossFeedbacksData.feedbackType === "KPI" && props.forms && (
          <KPIReviewForm
            reviewTitle={
              getKPIFeedback(crossFeedbacksData).feedbackQuestion.name
            }
            reviewValue={getKPIValueFromBulk(formsArr)}
            errors={props.errors.KPI}
            reasonObj={getKPIReason(crossFeedbacksData)}
            onChange={(starValue, reasonAnswers) => {
              const feedbacks: IFeedbackDetails[] = [];
              feedbacks.push({
                ...getKPIFeedback(crossFeedbacksData),
                values: [
                  {
                    value: starValue.toString(),
                    refQuestionAnswerId: "",
                  },
                ],
              });

              const kpiReason = getKPIReason(crossFeedbacksData);
              if (kpiReason) {
                feedbacks.push({
                  ...kpiReason,
                  values:
                    reasonAnswers.map((va) => ({
                      value: va,
                      refQuestionAnswerId: "",
                    })) ?? kpiReason.values,
                });
              }

              handleReviewChange("KPI", feedbacks);
            }}
          />
        )}

        {!isBulk() && (
          <Textarea
            max={256}
            name={`note_${crossFeedbacksData.feedbackType}`}
            placeholder={"Komentarz"}
            onChange={(e) => setNote(e.target.value)}
            value={note}
            errors={
              crossFeedbacksData.feedbackType === "SLA"
                ? props.errors.SLA
                : props.errors.KPI
            }
          />
        )}
        {crossFeedbacksData.id !== "" && (
          <Button
            onClick={undoReview}
            color={"red"}
            size={"xs"}
            appearance={"primary"}>
            Usuń ocenę
          </Button>
        )}
      </Section>
    </div>
  );
};

export default MyReviewForm;
