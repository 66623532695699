import React, { FunctionComponent, useEffect, useState } from "react";
import { ICreateCustomer } from "utils/models";
import { createCustomerState } from "utils/states";
import { useHistory, useParams } from "react-router-dom";
import { handleToast } from "utils/helpers";
import WhiteCard from "global/atoms/WhiteCard";
import CustomersConnection from "utils/connections/customers";
import Input from "global/atoms/Input";
import ActionsContainer from "global/atoms/ActionsContainer";
import ButtonSubmit from "global/atoms/ButtonSubmit";
import Form from "global/form/Form";
import { useDispatch } from "react-redux";
import Select from "global/atoms/Select";
import Spinner from "global/atoms/Spinner/Spinner";
import Section from "global/atoms/section/Section";
import { Checkbox, Col, IconButton, Row } from "rsuite";
import styles from "./styles.module.scss";
import ImageFormSelect from "../../../../global/atoms/ImageFormSelect";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import AddressPicker from "global/AddressPicker";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import EditIcon from "@rsuite/icons/Edit";
interface ICustomersEdit {}

interface IFormCustomerEdit {
  customerTypes: {
    options: Array<{
      id: string;
      name: string;
      type: string;
    }>;
  };
  parentCustomers: {
    options: Array<{
      id: string;
      name: string;
      type: string;
    }>;
  };
  orderFormats: {
    options: Array<{
      id: string;
      name: string;
      type: string;
    }>;
  };
  address: {
    id: string;
  };
}

const CustomersEdit: FunctionComponent<ICustomersEdit> = () => {
  const [data, setData] = useState<ICreateCustomer>(createCustomerState);
  const [errors, setErrors] = useState<any>({});
  const [form, setForm] = useState<IFormCustomerEdit | null>(null);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const type: "add" | "edit" = id === undefined ? "add" : "edit";

  if (type === "edit") {
    useEffect(() => {
      CustomersConnection.getCustomersFormEdit(id).then((response) => {
        setData({
          ...response.data.model,
          customerTypeId: response.data.model.customerType.id,
          customerType: response.data.model.customerType.type,
          parentCustomerId: response.data.model.parentCustomer?.id,
          addressId: response.data.model.address.id,
        });
        setForm(response.data);
        response.data?.header &&
          dispatch({ type: "SET_HEADER", payload: response.data.header });
      });
    }, []);
  } else {
    useEffect(() => {
      CustomersConnection.getCustomersFormAdd().then((response) => {
        setForm(response.data);
        response.data?.header &&
          dispatch({ type: "SET_HEADER", payload: response.data.header });
      });
    }, []);
  }

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (type === "add") {
      handleToast(CustomersConnection.createCustomer(data), setErrors)
        .then(() => history.push("/admin/customers"))
        .catch((error) => {
          if (error.response.status == 409)
            setErrors({ name: error.response.data.message });
        });
      return true;
    }

    handleToast(CustomersConnection.updateCustomer(id, data), setErrors)
      .then(() => history.push("/admin/customers"))
      .catch((error) => {
        if (error.response.status == 409)
          setErrors({ name: error.response.data.message });
      });
  };

  if (!form) return <Spinner />;

  return (
    <>
      <HeaderButtons>
        <IconButton
          appearance={"ghost"}
          icon={<EditIcon />}
          onClick={handleSubmit}>
          Zapisz
        </IconButton>
      </HeaderButtons>
      <WhiteCard padding={true} className={styles.wrapper}>
        <Form handleSubmit={handleSubmit}>
          <Row>
            <Col xs={3} style={{ textAlign: "center" }}>
              <ImageFormSelect
                label={"Wybierz logo"}
                state={data}
                setState={setData}
                name={"logo"}
              />
            </Col>
            <Col xs={21}>
              <div>
                <Input
                  type={"text"}
                  placeholder={"Nazwa"}
                  name={"name"}
                  value={data.name}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </div>
              <SeparatorEmpty size={0.75} />
              <div>
                <Select
                  placeholder={"Typ"}
                  name={"customerTypeId"}
                  value={data.customerTypeId}
                  options={form.customerTypes.options}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </div>
              {form?.customerTypes?.options.find(
                (option: any) => option.id === data.customerTypeId
              )?.type === "DISTRIBUTOR" && (
                <div>
                  <Select
                    style={{ marginTop: "17px" }}
                    placeholder={"Klient..."}
                    name={"parentCustomerId"}
                    value={data.parentCustomerId}
                    options={form.parentCustomers.options}
                    state={data}
                    setState={setData}
                    errors={errors}
                  />
                </div>
              )}
            </Col>
          </Row>

          <AddressPicker
            value={data.addressId}
            onSelected={(addressId) => {
              setData((d) => ({ ...d, addressId: addressId ?? "" }));
            }}
          />
          <Section title={"Dane ogólne"}>
            <Row>
              <Col xs={12}>
                <Input
                  type={"text"}
                  placeholder={"NIP"}
                  name={"nip"}
                  value={data.nip}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
                <Input
                  type={"text"}
                  placeholder={"Email"}
                  name={"email"}
                  value={data.email}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
                <Input
                  type={"text"}
                  placeholder={"Skrócona nazwa"}
                  name={"shortName"}
                  value={data.shortName}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
                <Input
                  type={"text"}
                  placeholder={"WWW"}
                  name={"www"}
                  value={data.www}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
              <Col xs={12}>
                <Input
                  type={"text"}
                  placeholder={"Kod"}
                  name={"code"}
                  value={data.code}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
                <Input
                  type={"text"}
                  placeholder={"Telefon"}
                  name={"phone"}
                  value={data.phone}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
                <Input
                  type={"text"}
                  placeholder={"Fax"}
                  name={"fax"}
                  value={data.fax}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
            </Row>
          </Section>

          <Section title={"FTP"} style={{ marginTop: "15px" }}>
            <Row>
              <Col xs={12}>
                <Input
                  type={"text"}
                  placeholder={"Adres"}
                  name={"ftpAddr"}
                  value={data.ftpAddr}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
                <Input
                  type={"text"}
                  placeholder={"User"}
                  name={"ftpUser"}
                  value={data.ftpUser}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
                <Input
                  type={"text"}
                  placeholder={"Hasło"}
                  name={"ftpPass"}
                  value={data.ftpPass}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
              <Col xs={12}>
                <Input
                  type={"text"}
                  placeholder={"Port"}
                  name={"ftpPort"}
                  value={data.ftpPort}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
                <Input
                  type={"text"}
                  placeholder={"Katalog"}
                  name={"repoDir"}
                  value={data.repoDir}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
            </Row>
          </Section>

          <Section title={"Konfiguracja"} style={{ marginTop: "15px" }}>
            <Row>
              <Col xs={24}>
                <Checkbox
                  checked={data.isEnableWorkTimeHistory}
                  onChange={(value, chk) =>
                    setData((d) => ({ ...d, isEnableWorkTimeHistory: chk }))
                  }>
                  Śledź czas pracy
                </Checkbox>
              </Col>
            </Row>
            <Row>
              <Select
                placeholder={"Format zamówienia"}
                name={"orderFormat"}
                value={data.orderFormat}
                options={form.orderFormats.options}
                state={data}
                setState={setData}
                errors={errors}
              />
            </Row>
          </Section>

          <ActionsContainer>
            <ButtonSubmit form={form} />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default CustomersEdit;
