import React from "react";
import { FCC } from "./models";

interface IPermissionElementWrapper {
  name: string;
  disabledElements?: string[];
}

const PermissionElementWrapper: FCC<IPermissionElementWrapper> = (props) => {
  if (props?.disabledElements?.includes(props.name) ?? true) return <></>;
  return <>{props.children}</>;
};

export default PermissionElementWrapper;
