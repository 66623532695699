import React from "react";
import { Message } from "rsuite";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import { v4 as uuidv4 } from "uuid";
import styles from "./styles.module.scss";
import MoveIcon from "@rsuite/icons/FolderMove";

interface IFilePathPicker {
  photoFilenamePattern: string;
}

const FolderPathPicker = (props: IFilePathPicker) => {
  const { photoFilenamePattern } = props;
  type IElement = {
    id: string;
    name: string;
    key?: string;
  };
  const pathElements: Array<IElement> = [
    { id: "photoId", name: "* Identyfikator zdjęcia" },
    { id: "photoNumericId", name: "* Numeryczny Identyfikator zdjęcia" },
    { id: "visitId", name: "Identyfikator wizyty" },
    { id: "questionId", name: "Identyfikator pytania" },
    { id: "projectName", name: "Nazwa projektu" },
    { id: "taskName", name: "Nazwa zadania" },
    { id: "activityName", name: "Nazwa aktywności" },
    { id: "questionCode", name: "Kod pytania w organizacji" },
    { id: "questionName", name: "Nazwa pytania" },
    { id: "locationId", name: "Identyfikator lokalizacji klienta" },
    { id: "locationName", name: "Nazwa lokalizacji" },
    { id: "locationCustomerCode", name: "Kod lokalizacji klienta" },
    { id: "photoInsertionYear", name: "Rok zapisu zdjęcia" },
    { id: "photoInsertionMonth", name: "Miesiąc zapisu zdjęcia" },
    { id: "photoInsertionDay", name: "Dzień zapisu zdjęcia" },
    { id: "photoInsertionHour", name: "Godzina zapisu zdjęcia" },
    { id: "photoInsertionMinute", name: "Minuta zapisu zdjęcia" },
    { id: "photoInsertionSecond", name: "Sekunda zapisu zdjęcia" },
  ];

  const items = photoFilenamePattern
    .split(/(\{[^}]+})/)
    .filter(Boolean)
    .map((part) => part.replace(/[{}]/g, ""));
  const photoFilenamePath = items.map((item) => {
    const element = pathElements.find((pathElement) => pathElement.id === item);
    if (element) return { ...element, key: element.id };
    return { id: uuidv4(), name: item, key: "" };
  });
  const pathUnusedElements = pathElements
    .filter(
      (elem) => photoFilenamePath.filter((e) => elem.id === e.id).length === 0
    )
    .map((el) => ({ ...el, key: el.id }));

  const PathElement = ({ item, draggable }) => {
    return (
      <div
        key={`file-path-element-${item.id}`}
        className={styles.configContainerBox}>
        <span
          style={{
            cursor: draggable ? "grab" : "default",
            background: "transparent",
            border: "none",
          }}
          draggable={draggable}
          onDragStart={(e) => {
            e.dataTransfer.setData("text/plain", `-{${item.id}}-`);
            e.currentTarget.style.opacity = "0.5";
          }}
          onDragEnd={(e) => {
            e.currentTarget.style.opacity = "1";
          }}>
          {draggable && (
            <>
              <MoveIcon />
              &nbsp;
            </>
          )}
          <b>{`{${item.key}}`}</b>
        </span>
        <br /> {item.name}
      </div>
    );
  };

  interface IPathElements {
    items: Array<IElement>;
    draggable?: boolean;
  }

  const PathElements = (props: IPathElements) => {
    const { items, draggable } = props;
    return (
      <div className={styles.configContainer}>
        {items.map((item: IElement, index: number) => {
          const elementConfig = pathElements.find(
            (pathElement) => pathElement.id === item.id
          );
          if (!elementConfig)
            return (
              <div
                key={`file-element-${index}`}
                title={"Element z poza listy"}
                style={{ position: "relative" }}
                className={styles.configContainerBox}>
                <br /> {item.name}
                {!["-", "_", "/"].includes(item.id) && (
                  <div
                    style={{
                      position: "absolute",
                      right: "5px",
                      top: "5px",
                      color: "red",
                    }}>
                    *
                  </div>
                )}
              </div>
            );
          return (
            <PathElement
              key={`file-element-${item.id}`}
              item={item}
              draggable={draggable}
            />
          );
        })}
      </div>
    );
  };

  return (
    <>
      <SeparatorEmpty />
      <Message
        header={
          <div style={{ textAlign: "center" }}>
            Format nazw plików zdjęciowych w paczkach .zip
          </div>
        }>
        <PathElements items={pathUnusedElements} draggable />
        <hr style={{ margin: "2px" }} />
        <PathElements items={photoFilenamePath} />
      </Message>
    </>
  );
};

export default FolderPathPicker;
