import { AxiosManager, HttpMethod, default as axios } from "../AxiosHelper";
import {
  getAuthHeader,
  getBaseApiUrl,
  getOrganizationId,
  getProjectIdFromUrl,
  handleToast,
} from "../helpers";
import { IDocumentsFilters } from "../../views/projects/components/documents/DocumentsList";

const DocumentsConnection = {
  list: (data: IDocumentsFilters) => {
    const projectId = getProjectIdFromUrl() ?? "";
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${projectId}/sales/documents/list`,
      data,
      {
        headers: getAuthHeader(),
      }
    );
  },
  filterForm: () => {
    const projectId = getProjectIdFromUrl() ?? "";
    return AxiosManager.send(
      HttpMethod.get,
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${projectId}/sales/documents/filter/form`
    );
  },
  editForm: (documentId: string, projectId: string) => {
    return axios.get(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${projectId}/sales/documents/${documentId}/form`,
      {
        headers: getAuthHeader(),
      }
    );
  },
  removeForm: (
    data: { documents: Array<{ id: string }> },
    projectId: string
  ) => {
    return axios.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${projectId}/sales/documents`,
      {
        headers: getAuthHeader(),
        data,
      }
    );
  },
  batchDelete: (projectId: string, data: Array<{ id: string }>) => {
    return axios.delete(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${projectId}/sales/documents`,
      {
        headers: { ...getAuthHeader() },
        data: { documents: data },
      }
    );
  },
  invoicesForm: (projectId: string, documents: string[]) => {
    return axios.post(
      `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${projectId}/sales/documents/invoices/form`,
      {
        documents: documents,
      }
    );
  },
  invoicesZip: (projectId: string, documents: string[]): any => {
    return handleToast(
      axios.post(
        `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${projectId}/sales/documents/invoices`,
        {
          documents: documents.map((documentId) => ({
            documentId: documentId,
          })),
        },
        {
          headers: getAuthHeader(),
          responseType: "blob",
        }
      )
    ).then((response) => {
      const csvURL = window.URL.createObjectURL(response.data);
      const tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", "faktury.zip");
      tempLink.click();
    });
  },
  invoicePdf: (projectId: string, documentId: string) => {
    const url = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/project/${projectId}/sales/documents/${documentId}/invoice`;
    return axios.get(url, {
      headers: getAuthHeader(),
      responseType: "blob",
    });
  },
};

export default DocumentsConnection;
