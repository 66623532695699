import React, { memo, useContext, useState } from "react";
import { FCC, IFeedbackDetails, IPhotoReviewFeedback } from "utils/models";
import {
  Checkbox,
  Dropdown,
  IconButton,
  InputGroup,
  Radio,
  RadioGroup,
  Rate,
} from "rsuite";

import FormErrorMessage from "rsuite/FormErrorMessage";
import FormGroup from "rsuite/FormGroup";
import FormControlLabel from "rsuite/FormControlLabel";
import InputGroupAddon from "rsuite/esm/InputGroup/InputGroupAddon";
import CloseIcon from "@rsuite/icons/Close";
import FormControl from "rsuite/FormControl";
import {
  BOOLEAN_QUESTION,
  DICTIONARY_MULTIPLE_ANSWERS_QUESTION,
  DICTIONARY_QUESTION,
  INTEGER_QUESTION,
} from "views/projects/components/visits/visitsEdit/VisitElements/QuestionTypeConsts";
import FlexRow from "../../../../../global/FlexRow";
import FlexRows from "../../../../../global/FlexRows";
import Connection, {
  ISubmitReviewData,
  ISubmitReviewDataPosition,
} from "../../../../../utils/connections/visitReviews";
import ReviewPassIcon from "@rsuite/icons/ReviewPass";
import { FeedbackStateContext } from "../../../../projects/components/visits/visitsEdit/VisitElements/Atoms/QuestionBody";
import BeatLoader from "react-spinners/BeatLoader";
import SeparatorLine from "../../../../../global/atoms/separators/SeparatorLine";
import ActionsContainer from "../../../../../global/atoms/ActionsContainer";
import { useStaticContext } from "../../../../projects/components/visits/visitsEdit/VisitElements/VisitHelpers";
interface IReviewForm {
  form: IPhotoReviewFeedback;
  forceUpdate?: boolean;
  inlineDictionaryQuestion?: boolean;
  questionAnswerIds: string[];
}

const ReviewForm: FCC<IReviewForm> = (props) => {
  const [errors, setErrors] = useState<any>();
  const [form, setForm] = useState<IPhotoReviewFeedback>(props.form);
  const getCheckedReasonValue = (el: IFeedbackDetails, id: string): boolean => {
    return (el.values ?? []).map((v) => v.value).includes(id);
  };

  const { setForceReload, setFormsFeedback, setFeedbackForm } =
    useContext(FeedbackStateContext) || {};

  const type = form.feedbackType;
  const title = (el: IFeedbackDetails) => {
    if (!el.values) return el.feedbackQuestion.name;
    return `${el.feedbackQuestion.name} (wybrano: ${el.values.length})`;
  };
  const { setReloadParent } = useStaticContext() || {};
  const updateValues = (
    value: string[] | string | undefined,
    el: IFeedbackDetails,
    questionIndex: number
  ) => {
    if (!form) return;
    const newForm = { ...form };
    let newValues = el.values ?? [];
    newValues = newValues.map((v) => ({
      value: v.value?.toString() ?? "",
      refQuestionAnswerId: v.refQuestionAnswerId,
    }));
    switch (el.feedbackQuestion.questionType) {
      case BOOLEAN_QUESTION:
        newValues?.[0]
          ? (newValues[0].value = value?.toString() ?? "0")
          : newValues.push({
              value: value?.toString() ?? "0",
              refQuestionAnswerId: "",
            });
        break;
      case DICTIONARY_QUESTION:
      case INTEGER_QUESTION:
        newValues?.[0]?.value
          ? (newValues[0].value = value?.toString() ?? "")
          : newValues.push({
              value: value?.toString() ?? "",
              refQuestionAnswerId: "",
            });
        break;
      case DICTIONARY_MULTIPLE_ANSWERS_QUESTION:
        newValues = el.feedbackQuestion.answers
          .filter((a) => value?.includes(a.id))
          .map((a) => ({ value: a.id, refQuestionAnswerId: "" }));
        break;
    }
    newForm.feedbackDetails[questionIndex].values = newValues;
    newForm.isChanged = true;
    setForm(newForm);
    setFormsFeedback?.((f) => {
      const ff = { ...f };
      props.questionAnswerIds.forEach((id) => {
        const questionAnswerForms = ff?.[id];
        if (questionAnswerForms) {
          const formIndex = questionAnswerForms.findIndex(
            (f) => f.feedbackType === form.feedbackType
          );
          ff[id][formIndex] = newForm;
        }
      });
      return ff;
    });
    if (props.forceUpdate) {
      submitReview();
    }
  };

  const updateNote = (value: string) => {
    if (!form) return;
    const newForm = { ...form };
    newForm.note = value;
    newForm.isChanged = true;
    setForm(newForm);
  };

  const getNormalizedValue = (
    question: IFeedbackDetails
  ): Array<string> | string | boolean | number => {
    const simpleValue = question.values
      ? question.values?.[0]?.value
      : question.feedbackQuestion.defaultAnswers?.[0];
    switch (question.feedbackQuestion.questionType) {
      case INTEGER_QUESTION:
      case DICTIONARY_QUESTION:
      case BOOLEAN_QUESTION:
        return simpleValue;
      case DICTIONARY_MULTIPLE_ANSWERS_QUESTION:
        return question.values
          ? question?.values?.map((v) => v.value)
          : question.feedbackQuestion.defaultAnswers;
      default:
        return simpleValue;
    }
  };

  const parseFeedbackDetailsForRequest = (
    feedbacks: IFeedbackDetails[]
  ): ISubmitReviewDataPosition[] => {
    return feedbacks.map((question) => {
      return {
        feedbackQuestionId: question.feedbackQuestion.id,
        values:
          question?.values?.map((v) => ({
            value: v.value.toString(),
            refQuestionAnswerId: "",
          })) ?? [],
      };
    });
  };

  const submitReview = () => {
    if (!form || !props.questionAnswerIds) return;
    const data: ISubmitReviewData = {
      refAnswerIds: props.questionAnswerIds,
      type: form.feedbackType,
      positions: parseFeedbackDetailsForRequest(form.feedbackDetails),
      note: form.note,
    };
    setReloadParent?.(true);
    Connection.submitAllReview(data)
      .then(() => {
        setReloadParent?.(true);
        setForceReload?.(true);
        setForm((f) => ({ ...f, isSubmit: false, isChanged: false }));
        setFeedbackForm?.(undefined);
      })
      .catch((err) => {
        setErrors?.(err?.response?.data?.errors);
        setForm((f) => ({ ...f, isSubmit: false, isChanged: true }));
      });
  };
  const integerQuestion = (el, questionIndex: number) => {
    const _value = getNormalizedValue(el);
    return (
      <>
        <Rate
          cleanable={false}
          onChange={(value) => {
            setErrors?.(undefined);
            updateValues(value.toString(), el, questionIndex);
          }}
          max={5}
          value={parseInt(_value as string)}
          size={"xs"}
        />
      </>
    );
  };

  const booleanQuestion = (el: IFeedbackDetails, questionIndex: number) => {
    return (
      <>
        <Checkbox
          style={{ minHeight: "20px", height: "20px" }}
          onChange={(value, checked) => {
            setErrors?.(undefined);
            updateValues(checked ? "1" : "0", el, questionIndex);
          }}
          checked={getNormalizedValue(el) == "1"}>
          {el.feedbackQuestion.name}
        </Checkbox>
      </>
    );
  };

  const dictionaryQuestion = (el: IFeedbackDetails, questionIndex: number) => {
    return (
      <>
        <div style={{ paddingLeft: "10px" }}>
          <SeparatorLine />
          <FormControlLabel style={{ fontWeight: "bold" }}>
            {el.feedbackQuestion.name}
          </FormControlLabel>
          <RadioGroup
            inline
            name={el.feedbackQuestion.id}
            value={getNormalizedValue(el) as string}
            onChange={(value) => {
              setErrors?.(undefined);
              updateValues(value.toString(), el, questionIndex);
            }}>
            {el.feedbackQuestion.answers.map((option, indexCheck) => {
              return (
                <>
                  <Radio
                    key={`${indexCheck}-${type}-${el.feedbackQuestion.id}`}
                    value={option.id}>
                    {option.name}
                  </Radio>
                </>
              );
            })}
          </RadioGroup>
        </div>
      </>
    );
  };

  const dictionaryMultipleQuestion = (
    el: IFeedbackDetails,
    questionIndex: number
  ) => {
    const isVisible = (): boolean => {
      const value = form?.feedbackDetails.find(
        (el) => el.feedbackQuestion.questionType == INTEGER_QUESTION
      )?.values?.[0]?.value;
      if (!value) return true;
      return parseInt(value) != 5;
    };
    const body = (el: IFeedbackDetails) => {
      return el.feedbackQuestion.answers.map((check) => (
        <>
          <Checkbox
            checked={getCheckedReasonValue(el, check.id)}
            value={check.id}
            onChange={(value, checked) => {
              setErrors?.(undefined);
              const _values =
                el?.values
                  ?.map((v) => v.value)
                  .filter((str) => str !== value) ?? [];
              if (checked) {
                _values.push(value as string);
              }
              updateValues(_values, el, questionIndex);
            }}>
            {check.name}
          </Checkbox>
        </>
      ));
    };
    if (props.inlineDictionaryQuestion) {
      return (
        <>
          <InputGroup>
            {isVisible() && (
              <>
                <FlexRows gap={0}>{body(el)}</FlexRows>
              </>
            )}
            <FormErrorMessage
              placement={"topStart"}
              show={!!errors?.[el.feedbackQuestion.id]}>
              {el.feedbackQuestion.name}: {errors?.[el.feedbackQuestion.id]}
            </FormErrorMessage>
          </InputGroup>
        </>
      );
    }
    return (
      <>
        <InputGroup>
          {isVisible() && (
            <Dropdown
              menuStyle={{ width: "300px" }}
              appearance={"ghost"}
              size={"xs"}
              title={title(el)}>
              {body(el)}
            </Dropdown>
          )}
          <FormErrorMessage
            placement={"topStart"}
            show={!!errors?.[el.feedbackQuestion.id]}>
            {el.feedbackQuestion.name}: {errors?.[el.feedbackQuestion.id]}
          </FormErrorMessage>
        </InputGroup>
      </>
    );
  };

  const SaveButton = (props: { form: IPhotoReviewFeedback }) => {
    const { form: _form } = props;
    return (
      <>
        <ActionsContainer>
          {form.feedbackType !== "COMPLETION_CHECK" && (
            <IconButton
              icon={<CloseIcon />}
              onClick={() => {
                setFeedbackForm?.(undefined);
                setForceReload?.(true);
              }}>
              Anuluj
            </IconButton>
          )}
          <IconButton
            disabled={_form.isSubmit || !_form.isChanged}
            size={"sm"}
            appearance={"ghost"}
            icon={
              <ReviewPassIcon
                style={{
                  color: _form?.isChanged ? "green" : "#E09616",
                }}
              />
            }
            onClick={() => {
              setForm((f) => ({ ...f, isChanged: false, isSubmit: true }));
              submitReview();
            }}>
            Zapisz
          </IconButton>
        </ActionsContainer>
        {_form.isSubmit && (
          <BeatLoader
            color={"#E09616"}
            style={{ display: "flex", justifyContent: "center" }}
          />
        )}
      </>
    );
  };

  const feedbackForm = (_form: IPhotoReviewFeedback) => {
    const _type = _form.feedbackType;
    return (
      <>
        {_form.feedbackDetails?.map((el, questionIndex) => {
          const questionType = el.feedbackQuestion.questionType;
          return (
            <>
              <FormGroup
                style={{ position: "relative", margin: "0px!important" }}>
                {questionType == INTEGER_QUESTION &&
                  integerQuestion(el, questionIndex)}
                {questionType == DICTIONARY_QUESTION &&
                  dictionaryQuestion(el, questionIndex)}
                {questionType == DICTIONARY_MULTIPLE_ANSWERS_QUESTION &&
                  dictionaryMultipleQuestion(el, questionIndex)}
                {questionType == BOOLEAN_QUESTION &&
                  booleanQuestion(el, questionIndex)}
              </FormGroup>
            </>
          );
        })}
        {_type == "COMPLETION_CHECK" && (
          <>
            <FormGroup style={{ paddingLeft: "35px", width: "100%" }}>
              <FormControlLabel>Komentarz</FormControlLabel>
              <FlexRow gap={"5px"}>
                <>
                  <InputGroup>
                    <FormControl
                      size={"sm"}
                      name={"note"}
                      value={_form.note}
                      onChange={(value: string) => {
                        updateNote(value);
                      }}
                    />
                    {_form.note && (
                      <InputGroupAddon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          updateNote("");
                          setErrors?.(undefined);
                        }}>
                        <CloseIcon />
                      </InputGroupAddon>
                    )}
                  </InputGroup>
                  <SaveButton form={_form} />
                </>
              </FlexRow>
            </FormGroup>
          </>
        )}
        {_type !== "COMPLETION_CHECK" && !props.forceUpdate && (
          <>
            <SaveButton form={_form} />
          </>
        )}
      </>
    );
  };

  if (!form) {
    return (
      <BeatLoader
        color={"#E09616"}
        style={{ display: "flex", justifyContent: "center" }}
      />
    );
  }

  return (
    <>
      <FormErrorMessage
        placement={"topEnd"}
        show={!!errors?.message || errors?.positions?.values[0]}>
        {errors?.message}
        {errors?.positions?.values[0]}
      </FormErrorMessage>
      <>
        <div
          style={{
            width: type == "KPI" ? "initial" : "100%",
            display: "flex",
            flexDirection:
              type == "KPI" && !props.inlineDictionaryQuestion
                ? "row"
                : "column",
            gap: "8px",
          }}>
          {feedbackForm(form)}
        </div>
      </>
    </>
  );
};

export default memo(ReviewForm);
