import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Message,
  Panel,
  Row,
  SelectPicker,
  TagPicker,
} from "rsuite";
import AlertsConnection from "../../../../../utils/connections/alertsConnection";
import {
  IAlertEntity,
  IAlertEntityStore,
  IProject,
  IUserMultiSelectRawEntity,
} from "../../../../../utils/models";
import SeparatorEmpty from "../../../../../global/atoms/separators/SeparatorEmpty";
import SpinnerSmall from "../../../../../global/atoms/Spinner/SpinnerSmall";
import ActionsContainer from "../../../../../global/atoms/ActionsContainer";
import {
  getProjectIdFromUrl,
  handleToast,
  handleToastRedux,
} from "../../../../../utils/helpers";
import { useDispatch } from "react-redux";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { confirmModalCallback } from "../../../../../redux/store";
import AlertFormActivities from "./AlertFormActivities";
import AlertFormUsers from "./AlertFormUsers";
import { TypeQuestion } from "utils/types";
import _ from "lodash";
import AlertFormConfig from "./AlertFormConfig";
import ProjectsConnection from "utils/connections/projects";
import { getProjectsState } from "utils/states";
import GlobalIcon from "@rsuite/icons/Global";
import FormErrorMessage from "rsuite/FormErrorMessage";
import dayjs from "dayjs";
import { beforeToday } from "rsuite/esm/DateRangePicker/disabledDateUtils";
import FormControlLabel from "rsuite/FormControlLabel";

export interface IAlertFormActivityQuestion {
  id: string;
  name: string;
  activityName: string;
  activityType: string;
  projectId: string;
  projectName: string;
  taskId: string;
  taskName: string;
  activityPrefix: string;
  activityQuestionPrefix: string;
  questionName: string;
  questionType: TypeQuestion;
}

const AlertForm: FunctionComponent = () => {
  interface IForm {
    predefinedReports: {
      data: Array<IAlertEntity>;
    };
    activities: {
      data: Array<IAlertFormActivityQuestion>;
    };
    users: {
      data: Array<IUserMultiSelectRawEntity>;
    };
  }

  const history = useHistory();
  const dispatch = useDispatch();

  const stateInit: IAlertEntityStore = {
    isEnableTasks: true,
    isEnableUsers: true,
    id: "",
    name: "",
    subName: "",
    nextGenDate: null,
    genTime: null,
    schedule: "",
    reportClass: "",
    recipients: [],
    activities: [],
    projectIds: getProjectIdFromUrl() ? [getProjectIdFromUrl() as string] : [],
    configOptions: {},
    title: "",
    body: "",
    isConfirmationRequired: true,
  };

  const [form, setForm] = useState<IForm | null>(null);
  const [state, setState] = useState<IAlertEntityStore>(stateInit);
  const [errors, setErrors] = useState<any>();
  const [projects, setProjects] = useState<Array<IProject>>([]);
  const { alertId } = useParams<{ alertId: string }>();
  const readOnly = getProjectIdFromUrl() && state.projectIds.length > 1;

  const loadForm = (projectIds: string[]) => {
    AlertsConnection.form(projectIds).then((res: { data: IForm }) => {
      setForm(res.data);
    });
  };

  const resetState = (_projectIds: Array<string> = [], _reportClass = "") => {
    setState({
      ...stateInit,
      projectIds: _projectIds,
      reportClass: _reportClass,
    });
  };

  useEffect(() => {
    if (!getProjectIdFromUrl()) {
      const request = getProjectsState;
      request.requestPaginate.limit = 999;
      ProjectsConnection.getProjects(request).then((res) =>
        setProjects(res.data.data)
      );
    }

    // edit
    if (alertId) {
      AlertsConnection.get(alertId).then((res) => {
        setState({
          ...res.data,
          nextGenDate: res.data.nextGenDate ?? dayjs().format("YYYY-MM-DD"),
          genTime: res.data.genTime ?? null,
          configOptions: !res.data.configOptions ? {} : res.data.configOptions,
          activities: res.data.activities.map((a) => ({
            ...a,
            activityQuestions: _.isEmpty(a.activityQuestions)
              ? undefined
              : a.activityQuestions,
          })),
        });
        loadForm(res.data.projectIds);
      });
    }
    // add
    else {
      loadForm(getProjectIdFromUrl() ? [getProjectIdFromUrl() as string] : []);
    }
  }, []);

  const availablePredefinedReports = (form?.predefinedReports?.data?.filter(
    (r) => {
      return !(state.projectIds?.length > 1 && !r.isEnableGroup);
    }
  ) ?? []) as IAlertEntity[];

  const handleReportChange = (index: number | null) => {
    if (index !== null)
      setState((s) => ({
        ...s,
        ...availablePredefinedReports[index],
        projectIds: s.projectIds,
        configOptions: {},
      }));
  };

  const redirectToList = () => {
    if (getProjectIdFromUrl()) {
      history.push(
        generatePath("/projects/:id/edit/alerts", {
          id: getProjectIdFromUrl() ?? "",
        })
      );
    } else {
      history.push("/notifications/auto");
    }
  };

  const onSubmit = () => {
    const stateClone: IAlertEntityStore = _.cloneDeep(state);
    if (!stateClone.configOptions.channel?.includes("app")) {
      stateClone.isConfirmationRequired = undefined;
    }
    const endpoint = alertId
      ? AlertsConnection.edit(stateClone)
      : AlertsConnection.add(stateClone);

    handleToast(endpoint, setErrors, "Uzupełnij pola formularza")
      .then(() => {
        redirectToList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDelete = () => {
    confirmModalCallback("Czy na pewno chcesz usunąć to powiadomienie?", () => {
      handleToastRedux(AlertsConnection.delete(alertId), dispatch).then(() => {
        redirectToList();
      });
    });
  };

  const getReportForm = (): IAlertEntity | null => {
    return (
      form?.predefinedReports?.data?.find(
        (d) => d.reportClass === state.reportClass
      ) ?? null
    );
  };

  const isEnabledSubject = () => {
    const reportClass = state.reportClass.split("\\").pop() ?? "";
    return (
      !state.configOptions.channel?.includes("mail") &&
      ["ReportNotificationVisitStatus", "ReportNotificationFeedback"].includes(
        reportClass
      )
    );
  };

  return (
    <>
      {getProjectIdFromUrl() && state.projectIds.length > 1 && (
        <>
          <Message>
            <GlobalIcon />
            &nbsp; Powiadomienie globalne, w celu edycji skontaktuj się z
            administratorem.
          </Message>
          <SeparatorEmpty size={1} />
        </>
      )}
      <Panel style={{ backgroundColor: "white" }}>
        {!form ? (
          <SpinnerSmall />
        ) : (
          <>
            {!getProjectIdFromUrl() && (
              <div>
                <label>Projekty*</label>
                <TagPicker
                  data={projects}
                  disabled={alertId !== undefined || !!readOnly}
                  labelKey={"name"}
                  valueKey={"id"}
                  block
                  onChange={(selected) => {
                    resetState(selected);
                  }}
                  value={state.projectIds}
                  renderMenu={(menu) => {
                    return (
                      <div>
                        <Checkbox
                          indeterminate={
                            !!state.projectIds.length &&
                            projects.length > state.projectIds.length
                          }
                          onChange={(value, checked) => {
                            if (checked) {
                              resetState(projects.map((p) => p.id));
                            } else {
                              resetState([]);
                            }
                          }}
                          checked={state.projectIds.length === projects.length}>
                          Zaznacz/odznacz wszystkie
                        </Checkbox>
                        <div style={{ paddingLeft: "20px" }}>{menu}</div>
                      </div>
                    );
                  }}
                />
                {!alertId && (
                  <small>
                    Nie wszystkie powiadomienia są dostępne dla zgrupowanych
                    powiadomień (wiele projektów)
                  </small>
                )}
                <SeparatorEmpty size={1} />
              </div>
            )}

            <Row>
              <Col xs={12}>
                <label>Typ powiadomienia*</label>
                <SelectPicker
                  data={availablePredefinedReports.map((r, index) => ({
                    value: index,
                    label: r.name,
                  }))}
                  block
                  cleanable={false}
                  disabled={
                    alertId !== undefined ||
                    _.isEmpty(state.projectIds) ||
                    !!readOnly
                  }
                  searchable={false}
                  value={availablePredefinedReports.findIndex(
                    (pr) => pr.reportClass === state.reportClass
                  )}
                  style={{ width: "100%" }}
                  onChange={handleReportChange}
                />
              </Col>
              <Col xs={12}>
                <label>Nazwa</label>
                <Input
                  placeholder={
                    form.predefinedReports.data.find(
                      (pr) => pr.reportClass === state.reportClass
                    )?.name ?? ""
                  }
                  name={"name"}
                  value={state.name}
                  disabled={state.reportClass === "" || !!readOnly}
                  onChange={(value) => {
                    setState((s) => ({
                      ...s,
                      name: value.toString(),
                    }));
                  }}
                />
                <FormErrorMessage show={errors?.name} placement={"bottomEnd"}>
                  {errors?.name}
                </FormErrorMessage>
              </Col>
            </Row>
            {["DAILY", "WEEK"].includes(state.schedule) && (
              <Row>
                <SeparatorEmpty />
                <Col xs={6}>
                  <label>Data następnego generowania</label>
                  <DatePicker
                    oneTap
                    block
                    isoWeek
                    showWeekNumbers
                    shouldDisableDate={beforeToday()}
                    placeholder="Data startu"
                    format={"yyyy-MM-dd"}
                    value={
                      state.nextGenDate
                        ? dayjs(state.nextGenDate).toDate()
                        : undefined
                    }
                    onChange={(value) => {
                      setState((s) => ({
                        ...s,
                        nextGenDate: value
                          ? dayjs(value).format("YYYY-MM-DD")
                          : null,
                      }));
                    }}
                    ranges={[
                      {
                        label: "Dziś",
                        value: new Date(),
                      },
                      {
                        label: "Jutro",
                        value: dayjs().add(1, "day").toDate(),
                      },
                      {
                        label: "Za tydzień",
                        value: dayjs().add(7, "day").toDate(),
                      },
                    ]}
                  />
                </Col>
                <Col xs={6}>
                  <label>Godzina generowania</label>
                  <DatePicker
                    block
                    placeholder="Godzina generowania"
                    format={"HH:mm"}
                    value={
                      state.genTime
                        ? new Date(
                            `${dayjs().format("YYYY-MM-DD")}T${state.genTime}`
                          )
                        : undefined
                    }
                    onChange={(value) => {
                      setState((s) => ({
                        ...s,
                        genTime: value ? dayjs(value).format("HH:mm") : null,
                      }));
                    }}
                    ranges={[
                      {
                        label: "18:00",
                        value: dayjs().set("m", 0).set("h", 18).toDate(),
                      },
                      {
                        label: "22:00",
                        value: dayjs().set("m", 0).set("h", 22).toDate(),
                      },
                    ]}
                  />
                </Col>
              </Row>
            )}
            {!_.isEmpty(getReportForm()?.configOptions) && (
              <>
                <SeparatorEmpty size={1} />
                <AlertFormConfig
                  readOnly={!!readOnly}
                  isEdit={alertId !== undefined}
                  setState={setState}
                  state={state}
                  configOptions={getReportForm()?.configOptions ?? []}
                />
                {isEnabledSubject() && (
                  <>
                    <SeparatorEmpty />
                    <Panel shaded header={"Treść wiadomości"}>
                      <div style={{ marginBottom: "10px" }}>
                        <FormControlLabel>
                          Temat&nbsp;
                          <sup style={{ color: "#8a8c8e" }}>
                            <span style={{ color: "red" }}>*</span>max 160
                            znaków
                          </sup>
                        </FormControlLabel>
                        <Input
                          maxLength={160}
                          value={state.title}
                          onChange={(value) => {
                            setState((s) => ({ ...s, title: value }));
                          }}
                        />
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <FormControlLabel>
                          Treść&nbsp;
                          <sup style={{ color: "#8a8c8e" }}>
                            <span style={{ color: "red" }}>*</span>max 5000
                            znaków
                          </sup>
                        </FormControlLabel>
                        <Input
                          as={"textarea"}
                          rows={5}
                          maxLength={5000}
                          value={state.body}
                          onChange={(value) => {
                            setState((s) => ({ ...s, body: value }));
                          }}
                        />
                      </div>
                    </Panel>
                  </>
                )}
              </>
            )}

            {state.reportClass !== "" && (
              <>
                <SeparatorEmpty size={1} />
                {getReportForm()?.isEnableTasks && (
                  <AlertFormActivities
                    disabled={!!readOnly}
                    questionPickerDisabled={
                      !getReportForm()?.isEnableQuestions || !!readOnly
                    }
                    stateActivities={state.activities}
                    setAlertState={setState}
                    formActivities={form.activities.data.filter((a) =>
                      state.projectIds.includes(a.projectId)
                    )}
                  />
                )}
                <SeparatorEmpty size={1} />
                {getReportForm()?.isEnableUsers && (
                  <AlertFormUsers
                    error={errors?.recipients}
                    disabled={!!readOnly}
                    stateUsers={state.recipients}
                    setAlertState={setState}
                    formUsers={form.users.data.filter((u) =>
                      state.projectIds.includes(u.projectId)
                    )}
                  />
                )}
              </>
            )}
          </>
        )}

        <SeparatorEmpty size={1.5} />

        <ActionsContainer>
          {!readOnly && alertId !== undefined && (
            <Button appearance={"primary"} color={"red"} onClick={onDelete}>
              Usuń
            </Button>
          )}
          {!readOnly && (
            <Button
              appearance={"ghost"}
              disabled={state.reportClass === ""}
              onClick={onSubmit}>
              {alertId === undefined ? "Dodaj" : "Zapisz"}
            </Button>
          )}
        </ActionsContainer>
      </Panel>
    </>
  );
};

export default AlertForm;
