import React, { FunctionComponent, useEffect, useState } from "react";
import Connection, {
  IPhotoReviewFormsMultiple,
} from "../../../../utils/connections/visitReviews";
import styles from "../../../../global/common.module.scss";
import rStyles from "../styles.module.scss";
import Section from "global/atoms/section/Section";
import SLAReviewForm from "./Form/SLAReviewForm";
import KPIReviewForm from "./Form/KPIReviewForm";
import {
  getKPIFeedback,
  getKPIReason,
  getKPIValueFromBulk,
} from "../../helper";
import { t } from "./MyReviewForm";
import stylesCommon from "../../../../global/common.module.scss";
import PropagateLoader from "react-spinners/PropagateLoader";
import { Message } from "rsuite";

interface ISupervisorReview {
  visitQuestionAnswerIds: Array<string>;
}

const MessageLoader = () => (
  <>
    <div
      className={stylesCommon.loadingSmall}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}>
      <PropagateLoader
        color={"#E09616"}
        style={{ height: "10px", padding: "20px" }}
      />
      <div>Ładowanie ocen...</div>
    </div>
  </>
);
const MessageEmpty = () => (
  <Message
    style={{ marginLeft: "10%", marginRight: "10%", fontSize: "10px" }}
    showIcon
    header={"Uwaga"}
    type={"info"}>
    Brak ocen zdjęć lub brak uprawnień
  </Message>
);

const SupervisorReviewForms: FunctionComponent<ISupervisorReview> = (props) => {
  const [forms, setForms] = useState<IPhotoReviewFormsMultiple | null>(null);
  const [loaded, setLoaded] = useState<boolean | null>(false);

  useEffect(() => {
    if (props.visitQuestionAnswerIds.length > 0) {
      Connection.getFeedbackAnswers(props.visitQuestionAnswerIds)
        .then((response) => {
          setForms(response.data);
          setLoaded(true);
        })
        .catch(() => {
          setLoaded(null);
        });
    }
  }, []);

  if (loaded === false) {
    return <MessageLoader />;
  }

  if (loaded === null) {
    return <MessageEmpty />;
  }

  if (!Object.keys(forms ?? []).length) {
    return (
      <div style={{ marginTop: "20px" }} className={styles.loadingSmall}>
        Brak ocen innych użytkowników.
      </div>
    );
  }

  return (
    <div style={{ opacity: 0.55 }}>
      {Object.values(forms ?? {}).map((formArr) =>
        formArr.map((form) => (
          <Section
            key={`otherReviewFormSection`}
            title={`${form.reviewer?.user.fullName} (${form.reviewer?.role.roleName})`}
            style={{ marginTop: "25px" }}>
            <div
              className={`${rStyles.reviewTypeWatermark} ${
                form.id !== "" && rStyles.active
              }`}>
              {t(form.feedbackType)}
            </div>

            {form.feedbackType === "SLA" && (
              <SLAReviewForm
                feedbackDetails={form.feedbackDetails}
                readOnly={true}
              />
            )}

            {form.feedbackType === "KPI" && (
              <KPIReviewForm
                reviewTitle={getKPIFeedback(form).feedbackQuestion.name}
                reviewValue={getKPIValueFromBulk([form])}
                reasonObj={getKPIReason(form)}
                readOnly={true}
              />
            )}

            {form.note && (
              <div className={styles.readOnlyComment}>
                <small style={{ color: "#3d3d3d" }}>Komentarz:</small>
                <div style={{ marginLeft: "15px" }}>{form.note}</div>
              </div>
            )}
          </Section>
        ))
      )}
    </div>
  );
};

export default SupervisorReviewForms;
