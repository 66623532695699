import React, { memo, useContext, useEffect } from "react";
import { FCC } from "utils/models";
import { TypeActivityType } from "utils/types";
import { IconButton, Rate } from "rsuite";
import { isEmpty } from "lodash";
import { INTEGER_QUESTION, PHOTO_QUESTION } from "../QuestionTypeConsts";
import MenuIcon from "@rsuite/icons/Menu";
import ReviewForm from "../../../../../../visitReviews/components/Review/Form/ReviewForm";
import { FeedbackStateContext } from "./QuestionBody";
import { useInView } from "react-intersection-observer";
import Connection from "../../../../../../../utils/connections/visitReviews";
import FeedbackFormDrawer from "./FeedbackFormDrawer";

interface IVisitReviewButton {
  itemId: string | undefined;
  activityType: TypeActivityType;
  visitId: string;
  forceUpdate?: boolean;
}
import styles from "./styles.module.scss";
const VisitReviewShortButton: FCC<IVisitReviewButton> = (props) => {
  const {
    formsFeedback,
    setFeedbackForm,
    question,
    setFormsFeedback,
    forceReload,
    setForceReload,
    hiddenElements,
    setCommonReview,
  } = useContext(FeedbackStateContext) || {};

  const questionAnswerIds =
    question?.values.map((ref) => ref.refQuestionAnswerId) ?? [];

  const form = formsFeedback && Object.values(formsFeedback)[0];

  if (
    question?.questionType !== PHOTO_QUESTION ||
    question?.values.filter((v) => v.refQuestionAnswerId !== "").length === 0
  ) {
    return <></>;
  }
  if (props.activityType === "LOCATION") return <></>;

  const loadForm = (questionAnswerIds: string[]) => {
    if (!questionAnswerIds || questionAnswerIds?.length === 0) return;
    return Connection.getAllFeedbackForms(questionAnswerIds).then((result) => {
      setFormsFeedback?.((f) => ({
        ...f,
        ...result.data,
      }));
      return result;
    });
  };
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      loadForm(questionAnswerIds);
    }
  }, [inView]);

  useEffect(() => {
    if (forceReload !== false) {
      loadForm(questionAnswerIds);
      setForceReload?.(false);
    }
  }, [forceReload]);

  const avgKpiValue = () => {
    let sum = 0;
    let count = 0;
    Object.values(formsFeedback ?? []).forEach((forms) => {
      const kpiForm = forms.find((form) => form.feedbackType == "KPI");
      if (kpiForm) {
        const value = kpiForm?.feedbackDetails.find(
          (q) => q.feedbackQuestion.questionType == INTEGER_QUESTION
        )?.values?.[0]?.value;
        if (value) {
          count++;
          sum += Number(value);
        }
      }
    });
    return count && sum / count;
  };

  return (
    <>
      <div ref={ref} />
      {!isEmpty(form) &&
        form?.map((_form, _formIndex) => {
          const type = _form?.feedbackType;
          if (type == "KPI") {
            if (
              hiddenElements?.includes(
                "SYSTEM_VISIT-QUESTION-ANSWER-FEEDBACK_PERMISSION_KPI_SEND"
              )
            ) {
              return <></>;
            }

            return (
              <>
                <div className={styles.rateAllHover}>
                  <Rate
                    allowHalf
                    cleanable={false}
                    onClick={() => {
                      setFeedbackForm?.(_form);
                      setCommonReview?.(true);
                    }}
                    onChange={(rate) => {
                      _form.isChanged = true;
                      const intQuestionIndex = _form.feedbackDetails.findIndex(
                        (q) =>
                          q.feedbackQuestion.questionType == INTEGER_QUESTION
                      );
                      try {
                        const _rate = Math.ceil(rate).toString();
                        _form.feedbackDetails[intQuestionIndex].values = [
                          {
                            value: _rate,
                            label: _rate,
                            refQuestionAnswerId:
                              _form.feedbackDetails[intQuestionIndex]
                                .feedbackQuestion.id,
                          },
                        ];
                      } catch (e) {
                        console.log(e);
                      }
                      setFeedbackForm?.(_form);
                      setCommonReview?.(true);
                    }}
                    max={5}
                    value={avgKpiValue()}
                    size={"xs"}
                    style={{
                      color: _form?.isChanged ? "green" : "#E09616",
                      marginLeft: "10px",
                    }}
                  />
                </div>
              </>
            );
          }
          if (type == "SLA")
            return (
              <>
                <IconButton
                  size={"xs"}
                  appearance={"ghost"}
                  icon={
                    <MenuIcon
                      style={{
                        color: _form.isChanged ? "green" : "#E09616",
                      }}
                    />
                  }
                  onClick={() => {
                    setFeedbackForm?.(_form);
                    setCommonReview?.(true);
                  }}>
                  Ocena SLA
                </IconButton>
              </>
            );

          if (type == "COMPLETION_CHECK")
            return (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}>
                  <ReviewForm
                    form={_form}
                    forceUpdate={props.forceUpdate}
                    questionAnswerIds={questionAnswerIds}
                  />
                </div>
              </>
            );
        })}
      <FeedbackFormDrawer forceUpdate={props.forceUpdate} />
    </>
  );
};

export default memo(VisitReviewShortButton);
