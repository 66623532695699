import React, { FunctionComponent, useState } from "react";
import {
  getProjectIdFromUrl,
  handleSortColumn,
  handleToast,
} from "utils/helpers";
import { _paginate30 } from "utils/states";
import { useParams } from "react-router-dom";
import { IHeader, IPagination, ISort } from "utils/models";
import { Table } from "rsuite";
import BadgeStatus from "global/atoms/badge/BadgeStatus";
import Pagination from "global/pagination/Pagination";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import {
  BatchActionButton,
  SingleActionButton,
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import StoreDocumentsConnection from "../../../../utils/connections/storeDocuments";
import UniversalFilters, {
  FILTER_INPUT_DATE,
  FILTER_INPUT_TEXT,
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import PermissionElementWrapper from "utils/PermissionElementWrapper";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import { downloadStoreDocuments } from "./StoreDocumentDetails";
import { useDispatch } from "react-redux";

interface IDocumentData {
  deliveryDate: string;
  documentDate: string;
  documentId: string;
  documentNumber: string;
  saleType: any;
  location: any;
  paymentDate: string;
  paymentType: string;
  positions: any;
  user: any;
}

interface IDocumentsData {
  columns: any;
  header: IHeader;
  data: Array<IDocumentData>;
  count: number;
}

export interface IDocumentsFilters {
  documentNumber: string;
  userId: string;
  saleTypeId: string;
  locationId: string;
  dateFrom: number;
  dateTo: number;
  requestPaginate: IPagination;
  requestOrder: ISort;
}

const StoreDocumentsList: FunctionComponent = () => {
  const [data, setData] = useState<IDocumentsData | null>(null);
  const [form, setForm] = useState<{ disabledElements: string[] } | null>();
  const defaultFilters: IDocumentsFilters = {
    dateFrom: 0,
    dateTo: 0,
    documentNumber: "",
    saleTypeId: "",
    locationId: "",
    userId: "",
    ..._paginate30,
  };
  const [filters, setFilters] = useState<IDocumentsFilters>(defaultFilters);
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);
  const [triggerLoad, setTriggerLoad] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);

  const handleCancelSelected = (deleteId: string) => {
    let toDelete = chosenToDelete.map((id) => ({ id: id }));
    if (deleteId !== undefined) {
      toDelete = [{ id: deleteId }];
    }
    handleToast(StoreDocumentsConnection.batchDelete(id, toDelete)).then(() => {
      setChosenToDelete([]);
      setTriggerLoad(Date.now());
    });
  };

  return (
    <>
      <HeaderButtons>
        <PermissionElementWrapper name={"cancelSelectedButton"}>
          <BatchActionButton
            chosenRows={chosenToDelete}
            callback={handleCancelSelected}
            title={"Anuluj dokumenty"}
            message={"Czy na pewno anulować? "}
          />
        </PermissionElementWrapper>
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            fillHeight
            loading={loading}
            data={data?.data ?? []}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            <Table.Column width={40} align="left">
              <Table.HeaderCell>
                <ToggleAllCheckbox
                  data={data?.data}
                  state={chosenToDelete}
                  setState={setChosenToDelete}
                  keyIdName={"documentId"}
                />
              </Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosenToDelete}
                    setState={setChosenToDelete}
                    selectedId={rowData.documentId}
                  />
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Numer</Table.HeaderCell>
              <Table.Cell dataKey="documentNumber">
                {(rowData) => (
                  <ButtonListRedirect
                    redirectUrl={`/projects/${id}/store-documents/${rowData.documentId}/details`}>
                    {rowData.documentNumber}
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} sortable>
              <Table.HeaderCell>Lokalizacja</Table.HeaderCell>
              <Table.Cell dataKey="locationName">
                {(rowData) => {
                  return <span>{rowData.location.name}</span>;
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} minWidth={150} align="left" sortable>
              <Table.HeaderCell>Wystawił</Table.HeaderCell>
              <Table.Cell dataKey="fullName">
                {(rowData) => {
                  return <span>{rowData.user.fullName}</span>;
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={100} align="left" sortable>
              <Table.HeaderCell>Typ</Table.HeaderCell>
              <Table.Cell dataKey="saleType">
                {(rowData) => (
                  <BadgeStatus color={rowData.status.color}>
                    {rowData.saleType.name}
                  </BadgeStatus>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={120} sortable>
              <Table.HeaderCell>Data</Table.HeaderCell>
              <Table.Cell dataKey="documentDate" />
            </Table.Column>
            <Table.Column width={100} align="left">
              <Table.HeaderCell>Akcje</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <ActionsContainer>
                    <FileDownloadIcon
                      style={{
                        cursor: "pointer",
                        color: "#E09616",
                        fontSize: "20px",
                      }}
                      onClick={() =>
                        downloadStoreDocuments(rowData.documentId, dispatch)
                      }
                    />
                    <PermissionElementWrapper
                      name={"cancelSelectedButton"}
                      disabledElements={form?.disabledElements}>
                      <SingleActionButton
                        callback={handleCancelSelected.bind(
                          null,
                          rowData.documentId
                        )}
                        message={"Czy na pewno anulować? "}
                      />
                    </PermissionElementWrapper>
                  </ActionsContainer>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            limit={filters.requestPaginate.limit.toString()}
            page={filters.requestPaginate.page.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={StoreDocumentsConnection.list}
        formGet={StoreDocumentsConnection.filterForm}
        getFormCallback={setForm}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={defaultFilters}
        setResultData={setData}
        filterStorageKey={`projectStoreDocuments_${getProjectIdFromUrl()}`}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "documentNumber",
            label: "Numer",
          },
          {
            type: FILTER_SELECT,
            stateKey: "locationId",
            formKey: "locations",
            label: "Lokalizacja",
          },
          {
            type: FILTER_INPUT_DATE,
            stateKey: "dateFrom",
            label: "Data od",
          },
          {
            type: FILTER_INPUT_DATE,
            stateKey: "dateTo",
            label: "Data do",
          },
          {
            type: FILTER_SELECT,
            stateKey: "userId",
            formKey: "users",
            label: "Wystawił",
          },
          {
            type: FILTER_SELECT,
            stateKey: "saleTypeId",
            formKey: "saleTypes",
            label: "Rodzaj dokumentu",
          },
        ]}
      />
    </>
  );
};

export default StoreDocumentsList;
