import { Drawer, Message } from "rsuite";
import ReviewForm from "../../../../../../visitReviews/components/Review/Form/ReviewForm";
import React, { useContext } from "react";
import { FeedbackStateContext } from "./QuestionBody";
import { t } from "../../../../../../visitReviews/components/Review/MyReviewForm";
import SeparatorLine from "../../../../../../../global/atoms/separators/SeparatorLine";

interface IFeedbackFormDrawer {
  forceUpdate?: boolean;
}

const FeedbackFormDrawer = (props: IFeedbackFormDrawer) => {
  const {
    feedbackForm,
    setFeedbackForm,
    question,
    forceReload,
    formsFeedback,
    commonReview,
  } = useContext(FeedbackStateContext) || {};

  const forceUpdate = props.forceUpdate ?? forceReload;
  if (!feedbackForm) return <></>;

  const feedbackFormIsOpen = !!feedbackForm;
  return (
    <>
      <Drawer
        open={feedbackFormIsOpen}
        placement={"left"}
        size={feedbackForm?.feedbackType == "KPI" ? "xs" : "md"}
        onClose={() => {
          setFeedbackForm?.(undefined);
        }}>
        <Drawer.Header>
          <Drawer.Title>
            {commonReview && "Zbiorcza"} Ocena zdjęcia:{" "}
            {t(feedbackForm?.feedbackType)}
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body style={{ padding: "20px" }}>
          <strong>{question?.questionName}</strong>
          <hr />
          {feedbackForm && (
            <>
              <ReviewForm
                inlineDictionaryQuestion
                forceUpdate={!!forceUpdate}
                form={feedbackForm}
                questionAnswerIds={
                  !commonReview
                    ? [feedbackForm.idVisitQuestion]
                    : (formsFeedback && Object.keys(formsFeedback)) ?? []
                }
              />
            </>
          )}
          <SeparatorLine />
          {commonReview && feedbackForm?.feedbackType == "KPI" && (
            <Message showIcon type={"info"} header={"Uwaga"}>
              Ocena zbiorcza nadpisuje oceny częściowe
            </Message>
          )}
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default FeedbackFormDrawer;
