import React, { FunctionComponent, useEffect, useState } from "react";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import Section from "../../../../global/atoms/section/Section";
import styles from "./styles.module.scss";
import { deepClone, getUserId, handleToast } from "../../../../utils/helpers";
import Input from "../../../../global/atoms/Input";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";

import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import { useHistory, useParams } from "react-router-dom";
import VisitsReportConnection from "../../../../utils/connections/visitsReport";
import { unstable_batchedUpdates } from "react-dom";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import { confirmModalHandler } from "../../../../redux/store";
import CheckTree from "rsuite/CheckTree";
import { Checkbox, Col, IconButton, Row } from "rsuite";
import FunnelIcon from "@rsuite/icons/Funnel";
import SortableList from "../../../../global/atoms/dnd/SortableList";
import { DragHandle } from "../../../../global/atoms/SortableItem";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";
import { unionBy } from "lodash";

export interface IVisitsReportTemplateData {
  name: string;
  data: any;
  projectId: string;
  asGlobal: boolean;
  asUser: string;
}

const rolesOptions = () => {
  return [
    {
      name: "supervisor",
      label: "Supervisor",
      active: false,
    },
    {
      name: "kierownik regionalny",
      label: "Kierownik regionalny",
      active: false,
    },
    {
      name: "supervisor klienta",
      label: "Supervisor klienta",
      active: false,
    },
    {
      name: "project manager",
      label: "Project Manager",
      active: false,
    },
    {
      name: "przedstawiciel handlowy",
      label: "Przedstawiciel handlowy",
      active: false,
    },
    {
      name: "area manager",
      label: "Area manager",
      active: false,
    },
  ];
};
const getSellerOptions = () => {
  return [
    {
      name: "name",
      label: "Nazwa",
      active: false,
    },
    {
      name: "street",
      label: "Ulica",
      active: false,
    },
    {
      name: "houseNumber",
      label: "Numer budynku",
      active: false,
    },
    {
      name: "apartmentNumber",
      label: "Numer mieszkania",
      active: false,
    },
    {
      name: "postcode",
      label: "Kod pocztowy",
      active: false,
    },
    {
      name: "city",
      label: "Miasto",
      active: false,
    },
    {
      name: "community",
      label: "Gmina",
      active: false,
    },
    {
      name: "district",
      label: "Powiat",
      active: false,
    },
    {
      name: "voivodeship",
      label: "Województwo",
      active: false,
    },
  ];
};

const columnsData = [
  {
    label: "Dane wizyty",
    name: "visit",
    active: false,
    options: [
      {
        name: "ordinalNumber",
        label: "Liczba porządkowa",
        active: false,
      },
      {
        name: "id",
        label: "Id wizyty",
        active: false,
      },
      {
        name: "projectName",
        label: "Nazwa projektu",
        active: false,
      },
      {
        name: "user",
        label: "Wykonawca",
        active: false,
      },
      ...rolesOptions(),
      {
        name: "status",
        label: "Status",
        active: false,
      },
      {
        name: "feedbackStatus",
        label: "Status odebrania odpowiedzi zwrotnej (feedback)",
        active: false,
      },
      {
        name: "toImproveStatus",
        label: "Czy zgłaszana do poprawy",
        active: false,
      },
      {
        name: "visitType",
        label: "Rodzaj wizyty",
        active: false,
      },
      {
        name: "week",
        label: "Tydzień",
        active: false,
      },
      {
        name: "month",
        label: "Miesiąc",
        active: false,
      },
      {
        name: "visitSendDate",
        label: "Data wysłania z aplikacji",
        active: false,
      },
      {
        name: "visitStartDate",
        label: "Data otwarcia w aplikacji",
        active: false,
      },
      {
        name: "visitEndDate",
        label: "Data zamknięcia w aplikacji",
        active: false,
      },
      {
        name: "visitCreatedDate",
        label: "Data utworzenia ankiety",
        active: false,
      },
      {
        name: "visitDate",
        label: "Data wizyty",
        active: false,
      },
      {
        name: "visitFillDate",
        label: "Data wypełnienia",
        active: false,
      },
      {
        name: "visitStartTime",
        label: "Godzina rozpoczęcia wizyty",
        active: false,
      },
      {
        name: "visitEndTime",
        label: "Godzina zakończenia wizyty",
        active: false,
      },
      {
        name: "visitTime",
        label: "Czas pracy w lokalizacji",
        active: false,
      },
      {
        name: "visitStartLocationDistance",
        label: "Wer. odl. - rozp.",
        active: false,
      },
      {
        name: "visitEndLocationDistance",
        label: "Wer. odl. - zapis",
        active: false,
      },
      {
        name: "visitVisitWorkingHour",
        label: "Godziny pracy od-do wg. harmonogram",
        active: false,
      },
      {
        name: "visitVisitWorkingHourSum",
        label: "Suma godzin pracy wg. harmonogram",
        active: false,
      },
      {
        name: "allVisitPhotosLink",
        label: "Link do zdjęć",
        active: false,
      },
      {
        name: "visitRating",
        label: "Ocena wizyty [%]",
        active: false,
      },
    ],
  },
  {
    label: "Dane punktu",
    name: "location",
    active: false,
    options: [
      {
        name: "id",
        label: "Id lokalizacji",
        active: false,
      },
      {
        name: "location_increment_number",
        label: "Nr porządkowy lokalizacji",
        active: false,
      },
      {
        name: "code",
        label: "Kod lokalizacji",
        active: false,
      },
      {
        name: "networkName",
        label: "Sieć",
        active: false,
      },
      {
        name: "networkLocationCode",
        label: "Kod sieci lokalizacji",
        active: false,
      },
      {
        name: "customer_code",
        label: "Kod lokalizacji (klienta)",
        active: false,
      },
      {
        name: "customer_region",
        label: "Region (klienta)",
        active: false,
      },
      {
        name: "nip",
        label: "Nip",
        active: false,
      },
      ...getSellerOptions(),
      {
        name: "type",
        label: "Typ",
        active: false,
      },
      {
        name: "category",
        label: "Kategoria",
        active: false,
      },
    ],
  },
  {
    label: "Dane nabywcy",
    name: "payer",
    active: false,
    options: [
      {
        name: "nip",
        label: "Nip",
        active: false,
      },
      ...getSellerOptions(),
    ],
  },
  {
    label: "Dane odbiorcy",
    name: "recipient",
    active: false,
    options: getSellerOptions(),
  },
  {
    label: "Zamówienie",
    name: "order",
    active: false,
    options: [
      {
        name: "documentNumber",
        label: "Numer dokumentu",
        active: false,
      },
      {
        name: "customer",
        label: "Dystrybutor",
        active: false,
      },
      {
        name: "user",
        label: "Wystawił",
        active: false,
      },
      {
        name: "documentDate",
        label: "Data dokumentu",
        active: false,
      },
      {
        name: "deliveryDate",
        label: "Data dostarczenia",
        active: false,
      },
    ],
  },
  {
    label: "Faktura",
    name: "document",
    active: false,
    options: [
      {
        name: "documentNumber",
        label: "Numer dokumentu",
        active: false,
      },
      {
        name: "customer",
        label: "Dystrybutor",
        active: false,
      },
      {
        name: "user",
        label: "Wystawił",
        active: false,
      },
      {
        name: "documentDate",
        label: "Data dokumentu",
        active: false,
      },
      {
        name: "deliveryDate",
        label: "Data dostarczenia",
        active: false,
      },
      {
        name: "paymentDate",
        label: "Data płatności",
        active: false,
      },
      {
        name: "status",
        label: "Status",
        active: false,
      },
      {
        name: "paymentType",
        label: "Rodzaj płatności",
        active: false,
      },
      {
        name: "terminalId",
        label: "ID urządzenia",
        active: false,
      },
      {
        name: "documentType",
        label: "Rodzaj dokumentu",
        active: false,
      },
      {
        name: "nip",
        label: "Nip nabywcy",
        active: false,
      },
      {
        name: "dailyReport",
        label: "Czy wykonano raport dobowy Tak/Nie",
        active: false,
      },
      {
        name: "isPaid",
        label: "Zapłacona",
        active: false,
      },
      {
        name: "countPrinted",
        label: "Liczba wydrukowanych",
        active: false,
      },
      {
        name: "priceAmountTotal",
        label: "Suma netto",
        active: false,
      },
      {
        name: "priceTaxTotal",
        label: "Suma vat",
        active: false,
      },
      {
        name: "priceAmountGrossTotal",
        label: "Suma brutto",
        active: false,
      },
    ],
  },
  {
    label: "Wydanie",
    name: "storeDocument",
    active: false,
    options: [
      {
        name: "documentNumber",
        label: "Numer dokumentu",
        active: false,
      },
      {
        name: "user",
        label: "Wystawił",
        active: false,
      },
      {
        name: "documentDate",
        label: "Data dokumentu",
        active: false,
      },
      {
        name: "status",
        label: "Status dokumentu",
        active: false,
      },
      {
        name: "priceAmountTotal",
        label: "Suma netto",
        active: false,
      },
      {
        name: "priceTaxTotal",
        label: "Suma vat",
        active: false,
      },
      {
        name: "priceAmountGrossTotal",
        label: "Suma brutto",
        active: false,
      },
    ],
  },
  {
    label: "Zadania Opcje",
    name: "taskOptions",
    active: false,
    height: "75px",
    sortable: false,
    options: [
      {
        name: "feedback",
        label: "Raport oceny zdjęć",
        active: false,
      },
      {
        name: "eanCode",
        label: "Kod EAN (wiersz)",
        active: true,
      },
      {
        name: "itemCategory",
        label: "Kategoria produktu (wiersz)",
        active: true,
      },
    ],
  },
  {
    label: "Raport oceny zdjęć, kolejność grup",
    name: "rolesOptions",
    active: false,
    height: "290px",
    options: rolesOptions(),
  },
];

type columnsDataType = Array<{
  label: string;
  name: string;
  active: boolean;
  height?: string;
  sortable?: boolean;
  options: Array<{
    name: string;
    label: string;
    active: boolean;
  }>;
}>;

const VisitsReportTemplateEdit: FunctionComponent = () => {
  const [state, setState] = useState<{
    asGlobal: boolean;
    asUser: string;
    templateName: string;
    columnsData: columnsDataType;
  }>({
    asGlobal: false,
    asUser: "",
    templateName: "",
    columnsData: columnsData,
  });
  const [form, setForm] = useState<any>(null);
  const [stateDisabledElements, setDisabledElements] = useState<Array<string>>(
    []
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [selectedTasks, setSelectedTasks] = useState<Array<string>>([]);
  const [selectedActivities, setSelectedActivities] = useState<Array<string>>(
    []
  );
  const { id, templateId } = useParams<{ id: string; templateId: string }>();
  const history = useHistory();
  const redirectUrl = `/projects/${id}/reports-visits`;

  const deepCloneTemplateUnion = (
    model: columnsDataType,
    template: columnsDataType,
    templateKey: string
  ): columnsDataType => {
    const mergedArray = unionBy(model, template, templateKey);

    mergedArray.forEach((item) => {
      const columnItem = model.find((i) => i.name === item.name);
      if (columnItem) {
        item.active = columnItem.active;
      }
      if (columnItem?.options) {
        const templateOption =
          template.find((i) => i.name === item.name)?.options || [];
        item.options = unionBy(
          columnItem.options,
          templateOption,
          templateKey
        ).map((option) => {
          const optionFromModel = columnItem.options.find(
            (o) => o.name === option.name
          );
          return optionFromModel
            ? { ...option, active: optionFromModel.active }
            : option;
        });
      }
    });

    return mergedArray;
  };

  if (templateId !== undefined) {
    useEffect(() => {
      Promise.all([
        handleToast(VisitsReportConnection.getGenerateReportViewData(id)),
        handleToast(VisitsReportConnection.getTemplateById(templateId)),
      ]).then((responses) => {
        setForm(responses[0].data);
        const _disabledElements = [
          ...responses[0].data.disabledElements,
          ...responses[1].data.disabledElements,
        ];
        setDisabledElements(_disabledElements);
        const data = responses[1].data.model;
        // force one render with multiple state changes
        unstable_batchedUpdates(() => {
          setSelectedTasks(data.data.tasks);
          setSelectedActivities(data.data.activities);

          const mergedColumnsData = deepCloneTemplateUnion(
            data.data.columns,
            columnsData,
            "name"
          );

          setState((s) => ({
            ...s,
            templateName: data.name,
            columnsData: mergedColumnsData,
            asGlobal: data.asGlobal,
            asUser: data.asUser,
          }));
          setLoading(false);
        });
      });
    }, []);
  } else {
    useEffect(() => {
      handleToast(VisitsReportConnection.getGenerateReportViewData(id)).then(
        (response) => {
          setForm(response.data);

          const allTasks: Array<string> = [];
          const allActivities: Array<string> = [];

          response.data.tasks.options.map((item: any) => {
            allTasks.push(item.id);
            item.activities.map((activity: any) => {
              allActivities.push(activity.id);
            });
          });
          setDisabledElements(response.data.disabledElements);
          setSelectedActivities(allActivities);
          setSelectedTasks(allTasks);

          makeAllOptionsActive();
          setLoading(false);
        }
      );
    }, []);
  }

  const makeAllOptionsActive = () => {
    const optionsClone = deepClone(columnsData);
    optionsClone.map((item: any, index: number) => {
      optionsClone[index].active = true;
      optionsClone[index].options.map((item2: any, index2: number) => {
        optionsClone[index].options[index2].active = true;
      });
    });

    setState((s) => ({ ...s, columnsData: optionsClone }));
  };

  const handleToggleSection = (index, checked) => {
    const dataClone = deepClone(state.columnsData);
    dataClone[index].active = checked;
    setState((s) => ({ ...s, columnsData: dataClone }));
  };

  const handleToggleOption = (index, value, checked) => {
    const dataClone = deepClone(state.columnsData);
    dataClone[index].options[value].active = checked;
    setState({ ...state, columnsData: dataClone });
  };
  const getIsEditTemplateDisabled = () => {
    return (
      stateDisabledElements.includes("editTemplateDisabled") ||
      stateDisabledElements.includes("addNewTemplateDisabled")
    );
  };
  const getIsSetAsGlobalDisabled = () => {
    return stateDisabledElements.includes("canSetTemplateAsGlobalDisabled");
  };

  const getIsDeleteDisabled = () => {
    return stateDisabledElements.includes("deleteTemplateDisabled");
  };

  const handleSave = () => {
    if (state.templateName === "") {
      return false;
    }

    const mergedData = {
      columns: state.columnsData.map((column) => ({
        name: column.name,
        label: column.label,
        active: column.active,
        options: column.options,
      })),
      tasks: selectedTasks,
      activities: selectedActivities,
    };

    const dataPost = {
      projectId: id,
      name: state.templateName,
      data: mergedData,
      asUser: getUserId(),
      asGlobal: state.asGlobal,
    };

    if (templateId !== undefined) {
      handleToast(
        VisitsReportConnection.updateTemplate(templateId, dataPost)
      ).then(() => {
        history.push(redirectUrl);
      });
      return true;
    }

    handleToast(VisitsReportConnection.saveTemplate(dataPost)).then(() => {
      history.push(redirectUrl);
    });
  };

  const handleSelectedNodes = (item: any) => {
    if (getIsEditTemplateDisabled()) {
      return;
    }

    let selectedActivitiesClone = [...selectedActivities];
    let selectedTasksClone = [...(selectedTasks ?? [])];

    if (!item.parent) {
      // parent was selected and is undefined
      if (item.check === true) {
        item.activities.forEach((itemSelected: any) =>
          selectedActivitiesClone.push(itemSelected.id)
        );
        selectedTasksClone.push(item.id);
      } else {
        item.activities.forEach((itemSelected: any) => {
          selectedActivitiesClone = selectedActivitiesClone.filter(
            (activityIdLoop) => activityIdLoop !== itemSelected.id
          );
        });
        selectedTasksClone = selectedTasksClone.filter(
          (taskIdLoop) => taskIdLoop !== item.id
        );
      }
    } else {
      if (item.check === false) {
        selectedActivitiesClone = selectedActivitiesClone.filter(
          (activityIdLoop) => activityIdLoop !== item.id
        );
        //#todo what about tasks
        //?selectedTasksClone = selectedTasksClone.filter((taskIdLoop) => taskIdLoop !== item.parent.id);
      } else {
        selectedActivitiesClone.push(item.id);
        selectedTasksClone.push(item.parent.id);
      }
    }
    setSelectedTasks(selectedTasksClone);
    setSelectedActivities(selectedActivitiesClone);
  };

  if (loading) return <Spinner />;

  const handleDeleteTemplate = () => {
    handleToast(VisitsReportConnection.deleteTemplate(templateId)).then(() => {
      history.push(redirectUrl);
    });
  };
  const sortable = true;
  const onSortEnd = (index, sortedOptions) => {
    const data = deepClone(state.columnsData);
    data[index].options = sortedOptions;
    setState((s) => ({ ...s, columnsData: data }));
  };
  const getDisabledTasks = () => {
    const disabled: string[] = [];
    const items: Array<{ id: string; activities?: { id: string }[] }> =
      form.tasks?.options;
    items?.forEach((task: { id: string; activities?: { id: string }[] }) => {
      disabled.push(task.id);
      (task.activities ?? []).forEach((item) => {
        disabled.push(item.id);
      });
    });
    return disabled;
  };
  const allSelected = (item) => {
    if (item.length == 0) return undefined;
    return (
      item?.options?.filter((e: any) => e.active).length == item.options.length
    );
  };

  const toggleAll = (section): void => {
    let toSet = false;
    if (!allSelected(section)) toSet = true;
    const newOptions = section.options.map((el) => ({ ...el, active: toSet }));
    const ColumnsData = deepClone(state.columnsData);
    const newColumnsData = ColumnsData.map((s) =>
      s.name == section.name ? { ...s, options: newOptions } : s
    );
    setState((s) => ({ ...s, columnsData: newColumnsData }));
  };
  return (
    <>
      <HeaderButtons>
        <>
          <IconButton
            icon={<EditIcon />}
            appearance={"ghost"}
            onClick={() => {
              history.goBack();
            }}>
            Anuluj
          </IconButton>
          {templateId !== undefined && (
            <IconButton
              disabled={getIsDeleteDisabled()}
              appearance={!getIsDeleteDisabled() ? "ghost" : "default"}
              icon={<TrashIcon />}
              onClick={confirmModalHandler(
                "Czy na pewno chcesz usunąć ten szablon?",
                handleDeleteTemplate
              )}>
              Usuń
            </IconButton>
          )}
          <IconButton
            icon={<EditIcon />}
            disabled={getIsEditTemplateDisabled()}
            appearance={!getIsEditTemplateDisabled() ? "ghost" : "default"}
            onClick={handleSave}>
            Zapisz
          </IconButton>
        </>
      </HeaderButtons>
      <WhiteCard padding={true}>
        <Row style={{ display: "flex", alignItems: "flex-end" }}>
          <Col xs={12}>
            <Input
              disabled={getIsEditTemplateDisabled()}
              name={"templateName"}
              value={state.templateName}
              placeholder={"Nazwa szablonu"}
              setState={setState}
              state={state}
            />
          </Col>
          <Col xs={4}>
            <Checkbox
              disabled={
                getIsSetAsGlobalDisabled() || getIsEditTemplateDisabled()
              }
              name={"asGlobal"}
              checked={state.asGlobal}
              onChange={(value, checked) => {
                setState((s) => ({ ...s, asGlobal: checked }));
              }}>
              Globalny
            </Checkbox>
          </Col>
          <Col xs={8}>
            <ActionsContainer>
              {!!columnsData && (
                <IconButton
                  appearance={"ghost"}
                  disabled={!columnsData || getIsEditTemplateDisabled()}
                  onClick={() => {
                    setState({ ...state, columnsData: columnsData });
                  }}
                  icon={<FunnelIcon />}>
                  Ustaw wartości domyślne
                </IconButton>
              )}
            </ActionsContainer>
          </Col>
        </Row>
        <Row>
          {state.columnsData.map((item, index) => {
            return (
              <Col xs={24} sm={24} md={12} lg={8} xl={6} key={index}>
                <SeparatorEmpty />
                <Section title={item.label} className={styles.sectionContainer}>
                  <div className={!item.active ? styles.sectionDisabled : ""} />
                  <Checkbox
                    style={{ margin: 0 }}
                    className={styles.topRightCornerCheckbox}
                    checked={item.active}
                    value={index}
                    onChange={handleToggleSection}
                    disabled={getIsEditTemplateDisabled()}
                  />
                  <Checkbox
                    checked={allSelected(item)}
                    onClick={() => {
                      toggleAll(item);
                    }}
                    disabled={getIsEditTemplateDisabled()}>
                    Zaznacz / odznacz wszystkie
                  </Checkbox>
                  <div
                    style={{
                      height: item.height ? item.height : "500px",
                      overflow: "auto",
                    }}>
                    <SortableList
                      onSortEnd={onSortEnd.bind(null, index)}
                      data={item.options}
                      idKeyName={"name"}
                      disabled={getIsEditTemplateDisabled()}
                      dragHandleActivator={true}
                      mapFunction={(option: any, indexOption: any) => (
                        <>
                          <div
                            key={index + " " + indexOption}
                            style={{ display: "flex" }}>
                            <Checkbox
                              style={{ width: "100%" }}
                              checked={option.active}
                              value={indexOption}
                              onChange={handleToggleOption.bind(null, index)}
                              disabled={getIsEditTemplateDisabled()}>
                              {option.label}
                            </Checkbox>
                            {sortable && item.sortable !== false && (
                              <DragHandle />
                            )}
                          </div>
                        </>
                      )}
                    />
                  </div>
                </Section>
              </Col>
            );
          })}
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <SeparatorEmpty />
            <Section title={"Zadania"}>
              <CheckTree
                height={500}
                defaultExpandAll
                labelKey="name"
                childrenKey="activities"
                valueKey="id"
                data={form.tasks.options}
                value={selectedActivities}
                onSelect={handleSelectedNodes}
                disabledItemValues={
                  getIsEditTemplateDisabled() ? getDisabledTasks() : []
                }
              />
            </Section>
          </Col>
        </Row>
      </WhiteCard>
    </>
  );
};

export default VisitsReportTemplateEdit;
