import React, { createContext, FunctionComponent, memo } from "react";
import styles from "../../styles.module.scss";
import VisitQuestion from "../VisitQuestion";
import {
  IPhotoReviewFeedback,
  IQuestionItem,
  IVisitPosition,
} from "../../../../../../../utils/models";
import { TypeActivityType } from "../../../../../../../utils/types";
import { Col, Form, Input, Row, Tooltip, Whisper } from "rsuite";
import IconSvg from "../../../../../../../global/atoms/IconHelper";
import { faAsterisk, faExclamation } from "@fortawesome/free-solid-svg-icons";
import VisitComment, { CommentsBadgeWrapper } from "../VisitComment";
import VisitReviewButton from "./VisitReviewButton";
import QuestionErrors from "./QuestionErrors";
import { isSuperAdmin } from "utils/helpers";
import { FormElementVisitEdit } from "utils/FormElement";
import { IVisitCommentItem } from "../../VisitEdit";
import VisitReviewShortButton from "./VisitReviewShortButton";
import SeparatorLine from "../../../../../../../global/atoms/separators/SeparatorLine";
import SeparatorEmpty from "../../../../../../../global/atoms/separators/SeparatorEmpty";
import {
  questionRate,
  questionValuesRate,
  useFormStateContext,
  usePhotoContext,
  useStaticContext,
} from "../VisitHelpers";
import { RawValue } from "../../VisitEditForm";
import { PHOTO_QUESTION } from "../QuestionTypeConsts";
import { IPhotoReviewFormsMultiple } from "../../../../../../../utils/connections/visitReviews";

interface IQuestionBody {
  activityType: TypeActivityType;
  dependedOnQuestion?: IQuestionItem;
  question: IQuestionItem;
  item?: IVisitPosition;
  readOnly: boolean;
  hiddenElements: Array<string>;
  disabledElements?: Array<string>;
  comments: IVisitCommentItem[];
  isDisabled: boolean;
  visitId?: string;
}

export interface IFeedbackContext {
  formsFeedback: IPhotoReviewFormsMultiple | undefined;
  setFormsFeedback: React.Dispatch<
    React.SetStateAction<IPhotoReviewFormsMultiple | undefined>
  >;
  question: IQuestionItem;
  forceReload?: string[] | boolean;
  setForceReload?: React.Dispatch<React.SetStateAction<boolean>>;
  hiddenElements: string[];
  feedbackForm?: IPhotoReviewFeedback | undefined;
  setFeedbackForm?: React.Dispatch<
    React.SetStateAction<IPhotoReviewFeedback | undefined>
  >;
  commonReview: boolean;
  setCommonReview: React.Dispatch<React.SetStateAction<boolean>>;
}
export const FeedbackStateContext = createContext<IFeedbackContext | undefined>(
  undefined
);

const QuestionBody: FunctionComponent<IQuestionBody> = memo(
  ({
    dependedOnQuestion,
    question,
    item,
    readOnly,
    activityType,
    hiddenElements,
    comments,
    visitId,
    isDisabled,
  }) => {
    const [commentsIsOpen, setCommentsIsOpen] = React.useState<boolean>(false);

    const staticContext = useStaticContext();
    const formState = useFormStateContext();

    const [forceReload, setForceReload] = React.useState<boolean>(false);
    const [commonReview, setCommonReview] = React.useState<boolean>(false);
    const [formsFeedback, setFormsFeedback] = React.useState<
      IPhotoReviewFormsMultiple | undefined
    >(undefined);
    const [feedbackForm, setFeedbackForm] = React.useState<
      IPhotoReviewFeedback | undefined
    >(undefined);

    const feedbackContext = {
      formsFeedback: formsFeedback,
      setFormsFeedback: setFormsFeedback,
      question: question,
      forceReload: forceReload,
      setForceReload: setForceReload,
      hiddenElements: hiddenElements,
      feedbackForm: feedbackForm,
      setFeedbackForm: setFeedbackForm,
      commonReview: commonReview,
      setCommonReview: setCommonReview,
    };

    const NameWrapped = () => {
      if (
        !readOnly &&
        (question.isRequired || question.dependOnQuestion.id !== "")
      ) {
        const tooltip = (
          <Tooltip>
            <div>{question.isRequired ? "Pytanie wymagane" : <></>}</div>
            <div>
              {question.dependOnQuestion.id ? (
                <>
                  {"Pytanie zależne od:"}
                  <br />
                  <strong>{dependedOnQuestion?.questionName}</strong>
                </>
              ) : (
                <></>
              )}
            </div>
          </Tooltip>
        );

        return (
          <Whisper placement={"bottomStart"} trigger="hover" speaker={tooltip}>
            <span style={{ fontSize: "1.1em" }}>
              {question.questionName}
              <div className={styles.qIconAttr}>
                {question.isRequired &&
                  IconSvg(faAsterisk, undefined, false, "red", { padding: 0 })}
                {question.dependOnQuestion.id !== "" &&
                  IconSvg(faExclamation, undefined, false, "orange", {
                    padding: 0,
                  })}
              </div>
            </span>
          </Whisper>
        );
      }

      return <span style={{ fontSize: "1.1em" }}>{question.questionName}</span>;
    };

    const isPhotoQuestion = question.questionType == PHOTO_QUESTION;
    if (!question) return <></>;

    const isEnableVisitRate =
      staticContext?.isEnableVisitRate && question.activityType !== "LOCATION";

    const { visitPhotos } = usePhotoContext();

    const VisitQuestionRate = () => {
      let currentValue: any;

      const questionSummaryRate = questionRate(question);
      if (!questionSummaryRate) return <></>;
      if (isPhotoQuestion) {
        currentValue = visitPhotos.filter(
          (q) => q.refQuestionId == question.refQuestionId
        );
      } else {
        currentValue = formState[question.refQuestionId];
      }

      const questionValuesSummaryRate = questionValuesRate(
        question,
        currentValue as RawValue
      );
      let color = "#eae8e8";
      if (!questionValuesSummaryRate && questionSummaryRate) color = "#e16b5b";
      if (
        questionValuesSummaryRate &&
        questionValuesSummaryRate != questionSummaryRate
      )
        color = "#e6ea73";
      if (
        questionValuesSummaryRate &&
        questionValuesSummaryRate == questionSummaryRate
      )
        color = "#87dc83";
      if (isDisabled) color = "#eae8e8";
      return (
        <Input
          style={{
            color: isDisabled ? "#aaa7a7" : "inherit",
            textAlign: "right",
            backgroundColor: color,
            width: "100%",
          }}
          readOnly
          value={
            questionSummaryRate
              ? `${questionValuesSummaryRate ?? 0} / ${
                  questionSummaryRate ?? 0
                }`
              : ""
          }
        />
      );
    };

    const questionComments = comments.filter(
      (c) => c.visitQuestionId === question.refQuestionId
    );

    return (
      <div className={"rs-form-control-wrapper"}>
        <Form.Group>
          <FeedbackStateContext.Provider value={feedbackContext}>
            <Col xs={24}>
              <Col sm={12} xs={24} md={8}>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "space-between",
                  }}>
                  <div
                    className={styles.vqNameContainer}
                    title={
                      isSuperAdmin() ? JSON.stringify(question) : undefined
                    }>
                    <NameWrapped />
                  </div>
                  {activityType !== "LOCATION" &&
                    (questionComments.length > 0 ||
                      staticContext.isEnableQuestionComment) && (
                      <>
                        <Whisper
                          placement={"bottomEnd"}
                          trigger={"hover"}
                          speaker={
                            <Tooltip>
                              Komentarze, rozwiń, aby{" "}
                              {staticContext.isEnableQuestionComment
                                ? "dodać nowy"
                                : "przeczytać"}{" "}
                              komentarz
                            </Tooltip>
                          }>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => setCommentsIsOpen(!commentsIsOpen)}>
                            <CommentsBadgeWrapper
                              len={
                                (
                                  comments.filter(
                                    (c) =>
                                      c.visitQuestionId ===
                                      question.refQuestionId
                                  ) ?? []
                                ).length
                              }
                            />
                          </div>
                        </Whisper>
                      </>
                    )}
                </div>
                {isPhotoQuestion && (
                  <>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}>
                      {staticContext.isEnabledFeedbackSend && (
                        <Col xs={24}>
                          <VisitReviewButton
                            visitId={visitId ?? ""}
                            itemId={item?.itemId ?? ""}
                            activityType={activityType}
                            question={question}
                          />
                        </Col>
                      )}
                      {staticContext.isEnabledFeedbackSend &&
                        !hiddenElements.includes(
                          FormElementVisitEdit.photoQuestionShortReview
                        ) && (
                          <Col xs={24}>
                            <VisitReviewShortButton
                              visitId={visitId ?? ""}
                              itemId={item?.itemId ?? ""}
                              activityType={activityType}
                              forceUpdate={false}
                            />
                          </Col>
                        )}
                    </Row>
                  </>
                )}
              </Col>
              <Col sm={12} xs={24} md={16}>
                <Col xs={isEnableVisitRate ? 22 : 24}>
                  <VisitQuestion
                    question={question}
                    isDisabled={isDisabled}
                    isReadOnly={readOnly}
                    key={`visitQuestion-${question.refQuestionId}`}
                  />
                </Col>
                {isEnableVisitRate && (
                  <Col xs={2}>
                    <VisitQuestionRate />
                  </Col>
                )}
              </Col>

              <QuestionErrors refQuestionId={question.refQuestionId} />
            </Col>
          </FeedbackStateContext.Provider>
          {activityType !== "LOCATION" &&
            (questionComments.length > 0 ||
              staticContext.isEnableQuestionComment) &&
            commentsIsOpen && (
              <>
                <VisitComment
                  key={`visit-comment-${question.refQuestionId}`}
                  refQuestionId={question.refQuestionId}
                  comments={questionComments}
                />
              </>
            )}
          <SeparatorEmpty />
          <SeparatorLine />
        </Form.Group>
      </div>
    );
  }
);

export default QuestionBody;
