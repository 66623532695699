// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RNkN88VddEyRHwfKdgSO .B3IgQvacvgyd7lztVNbf{margin-bottom:12px}.zpHb7kbagS7v_JM_zi0k{cursor:pointer;position:absolute;right:5px;padding:5px;bottom:10px;width:20px;display:flex;justify-content:space-between}.zpHb7kbagS7v_JM_zi0k:hover{border:2px solid #e09616;border-radius:5px;background-color:#f4ead7}.eybHGEaymtW8w9bnzWzh{box-sizing:border-box;display:inline-block;cursor:pointer;margin-left:5px;padding:2px}.eybHGEaymtW8w9bnzWzh:hover{padding:0;border:2px solid #e09616;border-radius:5px}.GIefc2cWC2mnawCqCD_K{display:block;height:120px}", "",{"version":3,"sources":["webpack://./src/views/projects/components/visits/visitsEdit/VisitElements/Atoms/styles.module.scss"],"names":[],"mappings":"AAGA,4CACE,kBAAA,CAGF,sBACE,cAAA,CACA,iBAAA,CACA,SAAA,CACA,WAAA,CACA,WAAA,CACA,UAAA,CACA,YAAA,CACA,6BAAA,CAGF,4BACE,wBAAA,CACA,iBAAA,CACA,wBAAA,CAGF,sBACE,qBAAA,CACA,oBAAA,CACA,cAAA,CACA,eAAA,CACA,WAAA,CAGF,4BACE,SAAA,CACA,wBAAA,CACA,iBAAA,CAGF,sBACE,aAAA,CACA,YAAA","sourcesContent":[".mapActivityWrapper {\n}\n\n.mapActivityWrapper .alert {\n  margin-bottom: 12px;\n}\n\n.rateHover {\n  cursor: pointer;\n  position: absolute;\n  right: 5px;\n  padding: 5px;\n  bottom: 10px;\n  width: 20px;\n  display: flex;\n  justify-content: space-between;\n}\n\n.rateHover:hover {\n  border: 2px solid #E09616;\n  border-radius: 5px;\n  background-color: #f4ead7;\n}\n\n.rateAllHover {\n  box-sizing: border-box;\n  display: inline-block;\n  cursor: pointer;\n  margin-left: 5px;\n  padding: 2px;\n}\n\n.rateAllHover:hover {\n  padding: 0;\n  border: 2px solid #E09616;\n  border-radius: 5px;\n}\n\n.innerLink {\n  display: block;\n  height: 120px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mapActivityWrapper": "RNkN88VddEyRHwfKdgSO",
	"alert": "B3IgQvacvgyd7lztVNbf",
	"rateHover": "zpHb7kbagS7v_JM_zi0k",
	"rateAllHover": "eybHGEaymtW8w9bnzWzh",
	"innerLink": "GIefc2cWC2mnawCqCD_K"
};
export default ___CSS_LOADER_EXPORT___;
