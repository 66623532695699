import React from "react";
import { FCC } from "utils/models";

interface IFlexRow {
  gap?: string;
}

const FlexRow: FCC<IFlexRow> = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignContent: "center",
        width: "100%",
        gap: props?.gap ?? "5px",
      }}>
      {props.children}
    </div>
  );
};

export default FlexRow;
