import React, { FunctionComponent } from "react";
import { TypeGpsPointType } from "../../../../../../../utils/types";
import { IGpsSimple } from "../../../../../../../utils/models";
import styles from "../../styles.module.scss";
import atomStyles from "./styles.module.scss";
import { IPoint } from "../../../../../../../global/googleMap/Map";
import IconSvg from "../../../../../../../global/atoms/IconHelper";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { Col, Message, Row } from "rsuite";
import {
  faCheck,
  faExclamationTriangle,
  faFlagCheckered,
  faMapPin,
  faPhotoVideo,
} from "@fortawesome/free-solid-svg-icons";
import SeparatorLine from "../../../../../../../global/atoms/separators/SeparatorLine";
import { formattedDistance } from "../VisitDistances";
import SeparatorEmpty from "../../../../../../../global/atoms/separators/SeparatorEmpty";
import dayjs from "dayjs";

interface IMarkerClusterWindowContent {
  gps: Array<IGpsSimple>;
  points: Array<IPoint>;
  location: null | google.maps.LatLngLiteral;
}

export const getPointTypeName = (pointType: TypeGpsPointType) => {
  switch (pointType) {
    case "ACTIVITY_START":
      return "Rozpoczęcie aktywności";
    case "ACTIVITY_END":
      return "Zakończenie aktywności";
    case "WORK_START":
      return "Rozpoczęcie pracy";
    case "WORK_END":
      return "Zakończenie pracy";
    case "PHOTO_QUESTION":
      return "Zdjęcie";
    case "ROUTE":
      return "Trasa";
    case "VISIT_SENT":
      return "Wizyta wysłana";
    case "VISIT_SENDING":
      return "Wysłanie wizyty";
  }
  return "";
};

const MarkerClusterWindowContent: FunctionComponent<
  IMarkerClusterWindowContent
> = (props) => {
  const getVisitPointsFromMapPoints = (
    points: Array<IPoint>
  ): Array<IGpsSimple> => {
    return points
      .map((p) => {
        const gpoint = props.gps.find((g) => g.id === p.id);
        if (!gpoint) throw "Unknown point ID";
        return gpoint;
      })
      .sort((a, b) => {
        if (new Date(a.saveDate).getTime() > new Date(b.saveDate).getTime())
          return 1;
        return -1;
      });
  };

  const gpsPoints = getVisitPointsFromMapPoints(props.points);
  const filteredPoints = gpsPoints.filter(
    (p) => p.pointType !== "ACTIVITY_END" && p.pointType !== "PHOTO_QUESTION"
  );
  const filteredPhotos = (pe: IGpsSimple) =>
    gpsPoints.filter(
      (p) => pe.activityId === p.activityId && p.pointType === "PHOTO_QUESTION"
    );

  const pointPhotoToElement = (p: IGpsSimple): JSX.Element => {
    const distance = formattedDistance(p);
    const saveDate = dayjs(new Date(p.saveDate)).format("HH:mm:ss");
    const outOfToleratedDistance = p.outOfToleratedDistance;
    const icon = outOfToleratedDistance
      ? IconSvg(faPhotoVideo, undefined, undefined, "#ff9800")
      : IconSvg(faPhotoVideo, undefined, undefined, "#4caf50");
    return rowPhotoWrapper(
      icon,
      saveDate,
      getPointTypeName(p.pointType),
      distance
    );
  };
  const pointToElement = (p: IGpsSimple): JSX.Element => {
    const saveDate = dayjs(new Date(p.saveDate)).format("HH:mm:ss");
    const distanceStart = formattedDistance(p);
    let distanceEnd = <></>;
    switch (p.pointType) {
      case "ACTIVITY_START": {
        const activityEnd = gpsPoints.find(
          (ap) =>
            ap.activityId === p.activityId && ap.pointType === "ACTIVITY_END"
        );
        distanceEnd = formattedDistance(p);
        const outOfToleratedDistance = p.outOfToleratedDistance; // todo: od lokalizacji gps lub od cluster marker position jeśli brak lokalizacji gps
        const icon = activityEnd // todo: hinty
          ? outOfToleratedDistance
            ? IconSvg(faExclamationTriangle, undefined, undefined, "#f44336")
            : IconSvg(faCheck, undefined, undefined, "#4caf50")
          : IconSvg(faExclamationTriangle, undefined, undefined, "#ff9800");
        const timeString = `${saveDate} - ${
          activityEnd
            ? dayjs(new Date(activityEnd.saveDate)).format("HH:mm:ss")
            : "Brak zakończenia"
        }`;
        return rowWrapper(
          icon,
          timeString,
          p.activityName ?? "",
          distanceStart,
          distanceEnd
        );
      }
      case "WORK_START":
        return rowWrapper(
          IconSvg(faMapPin),
          saveDate,
          getPointTypeName(p.pointType),
          distanceStart,
          distanceEnd
        );
      case "WORK_END":
        return rowWrapper(
          IconSvg(faFlagCheckered),
          saveDate,
          getPointTypeName(p.pointType),
          distanceStart,
          distanceEnd
        );
      case "ROUTE":
        return rowWrapper(
          IconSvg(faFlagCheckered),
          saveDate,
          getPointTypeName(p.pointType),
          distanceStart,
          distanceEnd
        );
    }
    return <>-</>;
  };

  const rowPhotoWrapper = (
    icon: JSX.Element,
    timeString: string,
    title: string,
    distance: JSX.Element
  ): JSX.Element => {
    return (
      <Row style={{ marginLeft: 20 }}>
        <Col xs={3}>
          <div style={{ position: "relative", top: "8px" }}>{icon}</div>
        </Col>
        <Col xs={12}>
          <small>
            {IconSvg(faClock, undefined, undefined, "#999", {
              fontSize: "8px",
              position: "relative",
              top: "-2px",
              paddingLeft: "0",
            })}
            {timeString}
          </small>
          <div>{title}</div>
        </Col>
        <Col xs={3} style={{ marginTop: "10px" }}>
          {distance}
        </Col>
      </Row>
    );
  };
  const rowWrapper = (
    icon: JSX.Element,
    timeString: string,
    title: string,
    distanceStart: JSX.Element,
    distanceEnd: JSX.Element
  ): JSX.Element => {
    return (
      <Row style={{ margin: 0 }}>
        <Col xs={3}>
          <div style={{ position: "relative", top: "8px" }}>{icon}</div>
        </Col>
        <Col xs={15}>
          <small>
            {IconSvg(faClock, undefined, undefined, "#999", {
              fontSize: "8px",
              position: "relative",
              top: "-2px",
              paddingLeft: "0",
            })}
            {timeString}
          </small>
          <div>{title}</div>
        </Col>
        <Col xs={3}>
          <Col xs={24}>{distanceStart}</Col>
          <Col xs={24}>{distanceEnd}</Col>
        </Col>
      </Row>
    );
  };

  // todo: component - nagłowek - adres lokalizacji, user, alerty na czerwono jak brak zakonczonej wizyty w danym obrębie
  // todo: punkt poza klastrem

  return (
    <div
      className={`${styles.visitMapInfoWindow} ${atomStyles.mapActivityWrapper}`}>
      {!props.location && (
        <Message className={atomStyles.alert} showIcon type="warning">
          Brak koordynatów lokalizacji
        </Message>
      )}
      {filteredPoints.map((p, i) => (
        <>
          {pointToElement(p)}
          {filteredPhotos(p).map((pf) => (
            <>
              {<SeparatorEmpty size={1} />}
              {pointPhotoToElement(pf)}
            </>
          ))}
          {i + 1 < filteredPoints.length && <SeparatorLine size={0.2} />}
        </>
      ))}
    </div>
  );
};

export default MarkerClusterWindowContent;
