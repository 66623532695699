import React, { FunctionComponent, useState } from "react";
import Modal from "rsuite/Modal";
import DatePicker from "rsuite/DatePicker";
import Col from "rsuite/Col";
import Button from "rsuite/Button";
import SchedulesConnection from "utils/connections/schedules";
import dayjs from "dayjs";
import localePl from "dayjs/locale/pl";
import { handleToastRedux } from "../../../utils/helpers";
import download from "downloadjs";
import { useDispatch } from "react-redux";
import SpinnerSmall from "../../../global/atoms/Spinner/SpinnerSmall";
import { IconButton } from "rsuite";
import CalendarIcon from "@rsuite/icons/Calendar";
import { SetState } from "../../../utils/models";
dayjs.locale(localePl);

export interface IScheduleExportData {
  dateFrom: Date;
  dateTo: Date;
}

export interface IExportScheduleModal {
  open: boolean;
  setOpen: SetState<boolean>;
  data: any;
}

const scheduleExportData = {
  dateFrom: new Date(dayjs().startOf("month").toDate()),
  dateTo: new Date(dayjs().endOf("month").toDate()),
};

const ModalExportSchedule: FunctionComponent<IExportScheduleModal> = (
  parentProps
) => {
  const { setOpen, ...props } = parentProps;
  const [modalData, setModalData] =
    useState<IScheduleExportData>(scheduleExportData);
  const [isWorking, setIsWorking] = useState(false);
  const dispatch = useDispatch();
  const handleFile = () => {
    setIsWorking(true);
    handleToastRedux(
      SchedulesConnection.getFile({ ...props.data, ...modalData }),
      dispatch
    ).then((res) => {
      download(res.data, res.headers["x-filename"] ?? null);
      setOpen(false);
      setIsWorking(false);
    });
  };

  return (
    <>
      <Modal
        {...props}
        size={"sm"}
        onClose={() => {
          setOpen(false);
        }}>
        <Modal.Header>
          <h4>Eksport harmonogramu - miesięczny</h4>
        </Modal.Header>

        <Modal.Body style={{ overflowX: "hidden" }}>
          {isWorking && (
            <SpinnerSmall
              style={{
                position: "absolute",
                textAlign: "center",
                width: "100%",
                zIndex: 10,
              }}
            />
          )}

          <Col
            xs={8}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              borderRight: "2px solid #E09616",
            }}>
            <IconButton
              size={"xs"}
              appearance={"ghost"}
              icon={<CalendarIcon />}
              onClick={() => {
                setModalData({
                  ...modalData,
                  dateFrom: dayjs().startOf("week").toDate(),
                  dateTo: dayjs().startOf("week").add(6, "day").toDate(),
                });
              }}>
              Bieżący tydzień
            </IconButton>
            <IconButton
              size={"xs"}
              appearance={"ghost"}
              icon={<CalendarIcon />}
              onClick={() => {
                setModalData({
                  ...modalData,
                  dateFrom: dayjs().startOf("week").add(1, "week").toDate(),
                  dateTo: dayjs()
                    .startOf("week")
                    .add(1, "week")
                    .add(6, "day")
                    .toDate(),
                });
              }}>
              Następny tydzień
            </IconButton>
            <IconButton
              size={"xs"}
              appearance={"ghost"}
              icon={<CalendarIcon />}
              onClick={() => {
                setModalData({
                  ...modalData,
                  dateFrom: new Date(dayjs().startOf("month").toDate()),
                  dateTo: new Date(dayjs().endOf("month").toDate()),
                });
              }}>
              Bieżący miesiąc
            </IconButton>
            <IconButton
              size={"xs"}
              appearance={"ghost"}
              icon={<CalendarIcon />}
              onClick={() => {
                setModalData({
                  ...modalData,
                  dateFrom: dayjs().startOf("month").add(1, "month").toDate(),
                  dateTo: dayjs()
                    .startOf("month")
                    .add(1, "month")
                    .endOf("month")
                    .toDate(),
                });
              }}>
              Następny miesiąc
            </IconButton>
          </Col>
          <Col xs={8}>
            Data od:
            <DatePicker
              isoWeek
              showWeekNumbers
              format={"yyyy-MM-dd"}
              oneTap
              value={modalData.dateFrom}
              block
              placeholder={"data Od.."}
              onChange={(value) =>
                setModalData({ ...modalData, dateFrom: value as Date })
              }
              shouldDisableDate={(date) =>
                dayjs(date).isBefore(dayjs().add(-1, "month"))
              }
              ranges={[
                {
                  label: "Dziś",
                  value: new Date(),
                },
              ]}
            />
          </Col>
          <Col xs={8}>
            Data do:
            <DatePicker
              isoWeek
              showWeekNumbers
              format={"yyyy-MM-dd"}
              oneTap
              value={modalData.dateTo}
              block
              placeholder={"data Do.."}
              onChange={(value) =>
                setModalData({ ...modalData, dateTo: value as Date })
              }
              shouldDisableDate={(date) =>
                dayjs(date).isAfter(dayjs().add(1, "month")) ||
                dayjs(date).isBefore(modalData?.dateFrom ?? dayjs())
              }
              ranges={[
                {
                  label: "Dziś",
                  value: new Date(),
                },
              ]}
            />
          </Col>
        </Modal.Body>

        <Modal.Footer style={{ marginTop: "10px" }}>
          <Button appearance={"ghost"} onClick={handleFile}>
            Pobierz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalExportSchedule;
