import React, { FunctionComponent, useState } from "react";
import {
  IBodyResponse,
  IMailerRequest,
  ISelect,
} from "../../../../utils/models";
import {
  getNotificationsState,
  MailerRequestState,
} from "../../../../utils/states";
import UniversalFilters, {
  FILTER_INPUT_DATE,
  FILTER_INPUT_TEXT,
  FILTER_SELECT,
} from "../../../../global/filters/UniversalFilters";
import NotificationsConnection from "../../../../utils/connections/notifications";
import Pagination from "../../../../global/pagination/Pagination";
import Table from "rsuite/Table";
import EmailFillIcon from "@rsuite/icons/EmailFill";
import AttachmentIcon from "@rsuite/icons/Attachment";
import { Checkbox, Drawer, Dropdown, IconButton, Message } from "rsuite";
import download from "downloadjs";
import { handleSortColumn, handleToastRedux } from "../../../../utils/helpers";
import { useDispatch } from "react-redux";
import BadgeStatus from "../../../../global/atoms/badge/BadgeStatus";
import NavOptionsWrapper from "../../../../utils/NavOptionsWrapper";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import { confirmModalCallback } from "../../../../redux/store";
import UniversalWebComponent from "global/webComponents/UniversalWebComponent";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import toastNotification, {
  ToastTypes,
} from "../../../../global/ToastNotification";

interface INotificationMailer {}

interface IRowData {
  attachments: Array<string>;
  body: string;
  confirmationDate: null | 0 | 1;
  confirmationDateTimestamp: number | string;
  confirmationRequired: { id: null | 0 | 1; name: string; color: string };
  confirmed: { id: null | 0 | 1; name: string; color: string };
  id: string;
  sendChannel: string;
  sentDate: null | string | number;
  createDate: string;
  sentDateTimestamp: null | string | number;
  status: string;
  subject: string;
  userId: string;
  username: string;
  sendFrom: string;
  sendTo: string;
  error: string;
}

interface IForm {
  sendStatuses: ISelect<{
    id: string;
    name: string;
    color: string;
  }>;
  sendChannels: ISelect<{
    id: string;
    name: string;
    color: string;
  }>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NotificationMailer: FunctionComponent<INotificationMailer> = () => {
  const [requestData, setRequestData] =
    useState<IMailerRequest>(MailerRequestState);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<null | IBodyResponse<IRowData>>(null);
  const [form, setForm] = useState<IForm | null>(null);
  const [drawerPreviewOpen, setDrawerPreviewOpen] = useState<boolean>(false);
  const [emailPreviewData, setEmailPreviewData] = useState<IRowData | null>(
    null
  );
  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
  const [triggerLoad, setTriggerLoad] = useState(0);

  const emailPreview = (rowData: IRowData) => {
    setDrawerPreviewOpen(true);
    setEmailPreviewData(rowData);
  };

  const downloadAttachments = (mailerId: string, attachments) => {
    const fileName: string | null = attachments?.[0]?.split("/")?.pop() ?? null;
    NotificationsConnection.getAttachmentsBlob(mailerId)
      .then((res) => {
        download(
          res.data,
          fileName ?? res.headers["x-filename"],
          res.data.type
        );
      })
      .catch((err) => {
        if (err.response.status === 422)
          toastNotification(ToastTypes.warning, "Nie znaleziono załączników");
      });
  };

  const handleCheckAll = (checked: boolean) => {
    if (checked) setSelectedIds(data?.data.map((d) => d.id) ?? []);
    else setSelectedIds([]);
  };

  const checkboxOnChange = (checked: boolean, rowId: string) => {
    if (selectedIds.includes(rowId) && !checked)
      setSelectedIds(selectedIds.filter((id) => id !== rowId));
    else if (!selectedIds.includes(rowId) && checked)
      setSelectedIds([...selectedIds, rowId]);
  };

  const sendSelected = () => {
    confirmModalCallback(
      "Czy na pewno wysłać ponownie zaznaczone wiadomości?",
      () => {
        handleToastRedux(
          NotificationsConnection.sendSelected(selectedIds),
          dispatch
        ).then(() => {
          setTriggerLoad(Date.now());
        });
        setSelectedIds([]);
      }
    );
  };

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper>
          <Dropdown.Item
            onClick={sendSelected}
            disabled={selectedIds.length === 0}>
            Zaznaczone wyślij ponownie ({selectedIds.length})
          </Dropdown.Item>
        </NavOptionsWrapper>
      </HeaderButtons>
      <PaginatedTableViewWrapper
        decreaseContainerSizePx={40}
        table={
          <Table
            fillHeight
            data={data?.data ?? []}
            loading={loading}
            sortColumn={requestData.requestOrder.field}
            sortType={requestData.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setRequestData)
            }>
            <Table.Column width={40}>
              <Table.HeaderCell>
                <Checkbox
                  indeterminate={
                    selectedIds.length > 0 &&
                    selectedIds.length < (data?.data.length ?? 0)
                  }
                  checked={
                    selectedIds.length > 0 &&
                    selectedIds.length === (data?.data.length ?? 0)
                  }
                  onChange={(value, checked) => handleCheckAll(checked)}
                />
              </Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <Checkbox
                    checked={selectedIds.includes(rowData.id)}
                    onChange={(value, checked) =>
                      checkboxOnChange(checked, rowData.id)
                    }
                  />
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={150} sortable>
              <Table.HeaderCell>Data</Table.HeaderCell>
              <Table.Cell dataKey="createDate">
                {(rowData) => {
                  return (
                    <>
                      <small>{rowData.createDate}</small>
                      <br />
                      <small style={{ color: "#aaa" }}>
                        {rowData.sentDate}
                      </small>
                    </>
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} sortable fullText>
              <Table.HeaderCell>Temat</Table.HeaderCell>
              <Table.Cell dataKey="subject">
                {(rowData) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                      }}>
                      <div>{rowData.subject}</div>
                      <div
                        style={{
                          position: "absolute",
                          padding: "0",
                          color: "rgba(134,134,134,0.48)",
                          fontSize: "0.7em",
                          top: "15px",
                        }}>
                        {rowData?.createdByName}
                      </div>
                    </div>
                  </>
                )}
              </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={2} minWidth={220} sortable>
              <Table.HeaderCell>Użytkownik</Table.HeaderCell>
              <Table.Cell dataKey="username">
                {(rowData) => {
                  return (
                    <div
                      style={{ whiteSpace: "break-spaces", fontSize: "0.9em" }}>
                      {rowData.username}
                    </div>
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column sortable>
              <Table.HeaderCell>Kanał</Table.HeaderCell>
              <Table.Cell dataKey="sendChannel">
                {(rowData) => {
                  const formStatus = form?.sendChannels?.options?.find(
                    (s) => s.id == rowData.sendChannel
                  );
                  return (
                    <BadgeStatus color={formStatus?.color ?? "#000"}>
                      {formStatus?.name ?? rowData.sendChannel}
                    </BadgeStatus>
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column width={120} sortable>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.Cell dataKey="sendStatus">
                {(rowData) => {
                  const formStatus = form?.sendStatuses?.options?.find(
                    (s) => s.id == rowData.status
                  );
                  return (
                    <BadgeStatus color={formStatus?.color ?? "#000"}>
                      {formStatus?.name ?? rowData.status}
                    </BadgeStatus>
                  );
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column align={"right"} width={120} sortable>
              <Table.HeaderCell>Potwierdzenie</Table.HeaderCell>
              <Table.Cell dataKey="confirmed">
                {(rowData) => {
                  if (
                    rowData.confirmed.id === null ||
                    !rowData.confirmationRequired.id
                  ) {
                    return (
                      <small style={{ color: "#aaa" }}>{"Nie wymagane"}</small>
                    );
                  } else {
                    return (
                      <BadgeStatus color={rowData.confirmed.color}>
                        {rowData.confirmed.name}
                      </BadgeStatus>
                    );
                    // return <CheckRoundIcon color={rowData.confirmed.color} />
                  }
                }}
              </Table.Cell>
            </Table.Column>

            <Table.Column align={"right"}>
              <Table.HeaderCell>Podgląd</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  if (rowData.sendChannel !== "MAIL") return "-";
                  return (
                    <>
                      {rowData.attachments.length > 0 && (
                        <>
                          <IconButton
                            appearance={"subtle"}
                            icon={<AttachmentIcon />}
                            onClick={downloadAttachments.bind(
                              null,
                              rowData.id,
                              rowData.attachments
                            )}
                          />
                        </>
                      )}
                      <IconButton
                        icon={<EmailFillIcon />}
                        appearance={"subtle"}
                        onClick={emailPreview.bind(null, rowData as IRowData)}
                      />
                    </>
                  );
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            page={requestData.requestPaginate.page.toString()}
            limit={requestData.requestPaginate.limit.toString()}
            setState={setRequestData}
          />
        }
      />

      <UniversalFilters
        load={NotificationsConnection.getMailerList}
        state={requestData}
        setState={setRequestData}
        setLoading={setLoading}
        setResultData={setData}
        getFormCallback={(_form) => setForm(_form)}
        defaultStateData={getNotificationsState}
        formGet={NotificationsConnection.getMailerForm}
        triggerLoad={triggerLoad}
        filterStorageKey={"notificationMailerList"}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            label: "Temat",
            stateKey: "subject",
          },
          {
            type: FILTER_SELECT,
            label: "Kanał dystrybucji",
            stateKey: "sendChannel",
            formKey: "sendChannels",
          },
          {
            type: FILTER_SELECT,
            label: "Użytkownik (odbiorca)",
            stateKey: "userId",
            formKey: "users",
          },
          {
            type: FILTER_SELECT,
            label: "Potwierdzenie - czy wymagane",
            stateKey: "confirmationRequired",
            formKey: "confirmationRequiredStatuses",
          },
          {
            type: FILTER_SELECT,
            label: "Potwierdzenie",
            stateKey: "confirmed",
            formKey: "confirmedStatuses",
          },
          {
            type: FILTER_SELECT,
            label: "Status",
            stateKey: "sendStatus",
            formKey: "sendStatuses",
          },
          {
            type: FILTER_INPUT_DATE,
            label: "Data planowanej wysyłki od",
            stateKey: "dateFrom",
            outputFormat: "phpTimestamp",
          },
          {
            type: FILTER_INPUT_DATE,
            label: "Data planowanej wysyłki do",
            stateKey: "dateTo",
            outputFormat: "phpTimestamp",
          },
        ]}
      />
      <Drawer
        placement={"bottom"}
        style={{ height: "80%" }}
        open={drawerPreviewOpen}
        onClose={() => setDrawerPreviewOpen(false)}>
        <Drawer.Header>
          <Drawer.Title>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <div>
                  {emailPreviewData?.subject ?? "-"}&nbsp;&nbsp;
                  <small>{emailPreviewData?.username ?? ""}</small>
                </div>
                <div
                  style={{
                    lineHeight: "16px",
                    fontSize: "0.8em",
                    opacity: ".6",
                  }}>
                  <div>
                    Od: <strong>{emailPreviewData?.sendFrom}</strong>
                  </div>
                  <div>
                    Do: <strong>{emailPreviewData?.sendTo}</strong>
                  </div>
                </div>
              </div>
              <div>
                {(emailPreviewData?.attachments.length ?? 0) > 0 && (
                  <>
                    <IconButton
                      appearance={"ghost"}
                      size={"sm"}
                      icon={<AttachmentIcon />}
                      onClick={downloadAttachments.bind(
                        null,
                        emailPreviewData?.id ?? "",
                        emailPreviewData?.attachments ?? []
                      )}>
                      Pobierz załączniki
                    </IconButton>
                  </>
                )}
              </div>
            </div>
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body style={{ overflow: "hidden", padding: 0 }}>
          {emailPreviewData?.error && (
            <div style={{ padding: "15px" }}>
              <Message showIcon type={"error"}>
                {emailPreviewData.error}
              </Message>
            </div>
          )}
          {emailPreviewData && emailPreviewData.body && (
            <div style={{ overflow: "auto", height: "95%", padding: "10px" }}>
              <UniversalWebComponent html={emailPreviewData.body} />
            </div>
          )}
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default NotificationMailer;
