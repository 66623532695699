import React, { useEffect } from "react";

import { IconButton, Message, Table } from "rsuite";
import ArrowLeftLineIcon from "@rsuite/icons/ArrowLeftLine";
import { NavLink, useHistory, useParams } from "react-router-dom";
import HeaderButtons from "../../../global/atoms/headerButtons/HeaderButtons";
import connection from "../../../utils/connections/visitReviews";
import BadgeStatus from "../../../global/atoms/badge/BadgeStatus";
import Spinner from "../../../global/atoms/Spinner/Spinner";
import { ISumaryReview } from "./VisitPhotoReview";
import toastNotification, {
  ToastTypes,
} from "../../../global/ToastNotification";

interface IUserReviewTable {}

const VisitUserPhotoReview = (props: ISumaryReview) => {
  const { userRoleId } = props;
  const history = useHistory();
  const { user } = useParams<{ user: string }>();
  const [reviewTable, setReviewTable] = React.useState<
    IUserReviewTable[] | null
  >(null);

  useEffect(() => {
    connection
      .getPhotoUserReview(userRoleId ?? user)
      .then((res) => {
        if (res.status === 200) setReviewTable(res.data);
        else {
          setReviewTable([]);
        }
      })
      .catch((err) => {
        setReviewTable([]);
        err?.response?.data?.message &&
          toastNotification(ToastTypes.info, err.response.data.message);
      });
    return () => {
      setReviewTable([]);
    };
  }, [props]);

  const getAnswerColor = (item: any) => {
    switch (item.type) {
      case "TO_IMPROVE":
        return "yellow";
      case "POSITIVE":
        return "green";
      case "NEGATIVE":
        return "red";
      default:
        return "#e8dddd";
    }
  };

  if (!reviewTable) return <Spinner />;
  return (
    <>
      {user && (
        <HeaderButtons>
          <IconButton
            appearance={"ghost"}
            onClick={() => {
              history.push("/visit-reviews");
            }}
            icon={<ArrowLeftLineIcon />}>
            Raport
          </IconButton>
        </HeaderButtons>
      )}
      <div style={{ minHeight: "600px" }}>
        {reviewTable && (
          <Table
            renderEmpty={() => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}>
                <Message style={{ width: "30%" }} showIcon header={"Info!"}>
                  Brak danych, lub inny problem konfiguracji
                </Message>
              </div>
            )}
            fillHeight
            minHeight={400}
            wordWrap="break-word"
            data={reviewTable ?? []}>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Lokalizacja</Table.HeaderCell>
              <Table.Cell dataKey="locationName" />
            </Table.Column>
            <Table.Column width={150}>
              <Table.HeaderCell>Raportujący</Table.HeaderCell>
              <Table.Cell dataKey="imageAuthor" />
            </Table.Column>
            <Table.Column flexGrow={0.5}>
              <Table.HeaderCell>Sieć</Table.HeaderCell>
              <Table.Cell dataKey="locationNetwork" />
            </Table.Column>
            <Table.Column width={150}>
              <Table.HeaderCell>Data wizyty</Table.HeaderCell>
              <Table.Cell dataKey="visitStartDate" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Ocena według kryteriów</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          rowGap: "5px",
                        }}>
                        {rowData.answers.map((item, index) => {
                          if (item.label)
                            return (
                              <BadgeStatus
                                color={"silver"}
                                borderColor={getAnswerColor(item)}
                                key={index}>
                                {item.label}
                              </BadgeStatus>
                            );
                          return <></>;
                        })}
                      </div>
                    </>
                  );
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={2}>
              <Table.HeaderCell>Link do wizyty</Table.HeaderCell>
              <Table.Cell fullText>
                {(rowData) => {
                  const visitLink = `/projects/${rowData.projectId}/visits/${rowData.visitId}/edit`;

                  return (
                    <>
                      <NavLink to={visitLink}>
                        {location.protocol + "//" + location.host + visitLink}
                      </NavLink>
                    </>
                  );
                }}
              </Table.Cell>
            </Table.Column>
          </Table>
        )}
      </div>
    </>
  );
};

export default VisitUserPhotoReview;
