import _ from "lodash";
import React, { PropsWithChildren, ReactNode } from "react";
import {
  AutoComplete,
  Button,
  ButtonToolbar,
  Checkbox,
  CheckboxGroup,
  DatePicker,
  Form,
  Input,
  InputGroup,
  Schema,
  SelectPicker,
  TagPicker,
} from "rsuite";
import { ItemDataType, TypeAttributes } from "rsuite/esm/@types/common";

export interface IFormRequredFields {
  text?: Array<string>;
  date?: Array<string>;
  select?: Array<string>;
}
interface IForm {
  onSubmit?: (checkStatus: boolean) => void;
  onChange: (formValue: any) => void;
  state: any;
  layout?: "inline" | "horizontal";
  htmlAutoCompleteDisabled?: boolean;
}
interface IFormRequred extends IForm {
  requiredFields: IFormRequredFields;
  customStringRules?: Array<{
    fieldName: string;
    rule: RegExp;
    message: string;
  }>;
}
export function FormRequired(props: PropsWithChildren<IFormRequred>) {
  const stringRequired = Schema.Types.StringType().isRequired(
    "To pole jest wymagane"
  );
  const dateRequired = Schema.Types.DateType().isRequired("Wybierz date");

  const rf: any = {};

  props.requiredFields.text?.forEach((field) => {
    rf[field] = stringRequired;
  });

  props.requiredFields.select?.forEach((field) => {
    rf[field] = stringRequired;
  });

  props.requiredFields.date?.forEach((field) => {
    rf[field] = dateRequired;
  });

  if (props.customStringRules) {
    props.customStringRules.forEach((r) => {
      rf[r.fieldName] = Schema.Types.StringType().addRule(
        (value) => r.rule.test(value),
        r.message
      );
    });
  }

  return (
    <Form
      model={Schema.Model(rf)}
      layout={props.layout}
      autoComplete={props.htmlAutoCompleteDisabled ? "off" : ""}
      onSubmit={props.onSubmit}
      onChange={props.onChange}
      formValue={props.state}>
      {props.children}
    </Form>
  );
}

export function FormDefault(props: PropsWithChildren<IForm>) {
  return (
    <Form
      onSubmit={props.onSubmit}
      layout={props.layout}
      onChange={props.onChange}
      formValue={props.state}>
      {props.children}
    </Form>
  );
}

interface IFormGroupDatePicker {
  label: string;
  fieldName: string;
  placement?: TypeAttributes.Placement;
  onChange?: () => void;
  cleanable?: boolean;
  helperText?: string;
  size?: TypeAttributes.Size;
}
export function FormGroupDatePicker(
  props: PropsWithChildren<IFormGroupDatePicker>
) {
  return (
    <Form.Group controlId={props.fieldName}>
      <Form.ControlLabel>{props.label}</Form.ControlLabel>
      <Form.Control
        isoWeek
        showWeekNumbers
        size={props.size ?? "md"}
        cleanable={props.cleanable ?? true}
        onChange={props.onChange}
        placement={props.placement ?? "auto"}
        name={props.fieldName}
        accepter={DatePicker}
        oneTap
        block
        ranges={[
          {
            label: "Dziś",
            value: new Date(),
          },
        ]}
      />
      {props.helperText && (
        <Form.HelpText style={{ padding: 0 }}>{props.helperText}</Form.HelpText>
      )}
    </Form.Group>
  );
}

const getSortMethod = (
  sortMethod: undefined | ((a: any, b: any) => number),
  sortNameAsc: boolean | undefined
): void | ((a: any, b: any) => number) => {
  if (sortMethod !== undefined && sortNameAsc === true) {
    throw "Sort method cannot be provided while sortNameAsc is set to true - choose one!";
  }

  if (sortNameAsc === true) {
    return (a: any, b: any) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    };
  }

  return sortMethod;
};

interface IFormGroupSelect {
  label?: string;
  placeholder?: string;
  fieldName: string;
  labelKey?: string;
  valueKey?: string;
  options: Array<any>; //FormControlPickerProps
  disabled?: boolean;
  readonly?: boolean;
  sortMethod?: (a: any, b: any) => number;
  sortNameAsc?: boolean;
  size?: TypeAttributes.Size;
  onChange?: (value: any) => void;
  placement?: TypeAttributes.Placement;
  renderExtraFooter?: () => ReactNode;
  cleanable?: boolean;
  helperText?: string;
  onSearch?: (search: string) => void;
  renderMenu?: (menu: React.ReactNode) => React.ReactNode;
  renderMenuItem?: (label: ReactNode, item: ItemDataType) => ReactNode;
  renderMenuGroup?: (groupTitle: ReactNode, item: ItemDataType) => ReactNode;
  errorMessage?: string;
  loading?: boolean;
  groupBy?: string;
  virtualized?: boolean;
}
export function FormGroupSelect(props: PropsWithChildren<IFormGroupSelect>) {
  const sortFn = getSortMethod(props.sortMethod, props.sortNameAsc);
  return (
    <Form.Group controlId={props.fieldName}>
      {props.label && <Form.ControlLabel>{props.label}</Form.ControlLabel>}
      <InputGroup style={{ width: "100%" }}>
        <Form.Control
          block
          virtualized={props.virtualized}
          errorMessage={props.errorMessage}
          errorPlacement={"bottomEnd"}
          size={props.size ?? "md"}
          name={props.fieldName}
          cleanable={props.cleanable ?? true}
          // appearance={'subtle'}
          placement={props?.placement ?? "auto"}
          style={{ minWidth: "110px" }}
          menuStyle={{ width: "500px" }}
          loading={props.loading}
          onSearch={props.onSearch}
          readOnly={props.readonly}
          renderMenu={props.renderMenu}
          disabled={props.disabled || _.isEmpty(props.options)}
          placeholder={_.isEmpty(props.options) ? "Brak" : props.placeholder}
          sort={sortFn ? () => sortFn : undefined}
          data={props.options}
          onChange={props.onChange}
          labelKey={props.labelKey ?? "name"}
          valueKey={props.valueKey ?? "id"}
          accepter={SelectPicker}
          groupBy={props.groupBy}
          renderExtraFooter={props.renderExtraFooter}
          renderMenuGroup={(groupTitle) => {
            return (
              <>
                <div
                  style={{
                    color: "#e09616",
                    fontSize: "14px",
                    fontWeight: "bolder",
                  }}>
                  {groupTitle}
                </div>
              </>
            );
          }}
          renderMenuItem={props.renderMenuItem}
        />
        {props.children}
      </InputGroup>
      {props.helperText && (
        <Form.HelpText style={{ padding: 0 }}>{props.helperText}</Form.HelpText>
      )}
    </Form.Group>
  );
}

interface IFormGroupText {
  label?: string;
  fieldName: string;
  disabled?: boolean;
  placeholder?: string;
  size?: TypeAttributes.Size;
  helperText?: string;
  onClick?: () => void;
  errorMessage?: string;
}
export function FormGroupText(props: PropsWithChildren<IFormGroupText>) {
  return (
    <Form.Group controlId={props.fieldName}>
      {props.label && <Form.ControlLabel>{props.label}</Form.ControlLabel>}
      <Form.Control
        size={props.size ?? "md"}
        name={props.fieldName}
        style={{ width: "100%" }}
        disabled={props.disabled}
        placeholder={props.placeholder}
        accepter={Input}
        onClick={props.onClick}
        errorMessage={props?.errorMessage}
        errorPlacement={"bottomEnd"}
      />
      {props.helperText && (
        <Form.HelpText style={{ padding: 0 }}>{props.helperText}</Form.HelpText>
      )}
    </Form.Group>
  );
}

interface IFormGroupCheckbox {
  label?: string;
  fieldName: string;
  disabled?: boolean;
  size?: TypeAttributes.Size;
}
export function FormGroupCheckbox(
  props: PropsWithChildren<IFormGroupCheckbox>
) {
  return (
    <Form.Group controlId={props.fieldName}>
      <Form.Control
        block
        size={props.size ?? "md"}
        name={props.fieldName}
        style={{ width: "100%" }}
        disabled={props.disabled}
        accepter={CheckboxGroup}>
        <Checkbox value={1}>{props.label}</Checkbox>
      </Form.Control>
    </Form.Group>
  );
}

interface IFormCheckbox {
  label?: string;
  fieldName: string;
  disabled?: boolean;
  size?: TypeAttributes.Size;
  checked?: boolean;
  onChange?: (value: string) => void;
}
export function FormCheckbox(props: PropsWithChildren<IFormCheckbox>) {
  return (
    <Form.Group controlId={props.fieldName}>
      <Form.Control
        block
        size={props.size ?? "md"}
        name={props.fieldName}
        style={{ width: "100%" }}
        disabled={props.disabled}
        accepter={Checkbox}
        checked={props.checked}>
        {props.label}
      </Form.Control>
    </Form.Group>
  );
}

export function FormGroupMultiSelect(props: IFormGroupSelect) {
  const sortFn = getSortMethod(props.sortMethod, props.sortNameAsc);
  return (
    // <ErrorBoundary fallback={<>Wystąpił błąd!</>}>
    <Form.Group controlId={props.fieldName}>
      {props.label && <Form.ControlLabel>{props.label}</Form.ControlLabel>}
      <Form.Control
        block
        cleanable={props.cleanable ?? true}
        placement={"auto"}
        size={props.size ?? "md"}
        name={props.fieldName}
        virtualized={props.virtualized}
        loading={props.loading}
        style={{ minWidth: "110px" }}
        sort={sortFn ? () => sortFn : undefined}
        disabled={props.disabled || _.isEmpty(props.options)}
        placeholder={_.isEmpty(props.options) ? "Brak" : props.placeholder}
        data={props.options}
        labelKey={props.labelKey ?? "name"}
        valueKey={props.valueKey ?? "id"}
        accepter={TagPicker}
        onSearch={props.onSearch}
        readOnly={props.readonly}
        renderMenu={props.renderMenu}
        renderMenuItem={props.renderMenuItem}
        menuStyle={{ width: "500px" }}
        onChange={props.onChange}
        groupBy={props.groupBy}
      />
      {props.helperText && (
        <Form.HelpText style={{ padding: 0 }}>{props.helperText}</Form.HelpText>
      )}
    </Form.Group>
    // </ErrorBoundary>
  );
}

interface IFormGroupButtons {
  label: string;
  disabled?: boolean;
}
export function FormGroupSubmit(props: PropsWithChildren<IFormGroupButtons>) {
  return (
    <Form.Group>
      <ButtonToolbar>
        <Button
          disabled={props.disabled}
          appearance={"primary"}
          type={"submit"}>
          {props.label}
        </Button>
      </ButtonToolbar>
    </Form.Group>
  );
}

export function AutoCompleteInput(
  props: PropsWithChildren<{
    label: string;
    fieldName: string;
    options: string[];
    disabled?: boolean;
    filterBy?: (value: string, item: ItemDataType) => boolean;
    inputAddon?: React.ReactElement;
  }>
) {
  return (
    <Form.Group controlId={props.fieldName}>
      <Form.ControlLabel>{props.label}</Form.ControlLabel>
      <InputGroup style={{ width: "100%" }}>
        <Form.Control
          name={props.fieldName}
          autoComplete={"true"}
          data={props.options}
          disabled={props?.disabled}
          filterBy={props.filterBy}
          accepter={AutoComplete}
        />
        {props.inputAddon && (
          <InputGroup.Addon>{props.inputAddon}</InputGroup.Addon>
        )}
      </InputGroup>
    </Form.Group>
  );
}
