import React, { FunctionComponent, useEffect } from "react";
import styles from "./header.module.scss";
import User from "global/user/User";
import { useDispatch, useSelector } from "react-redux";
import { IHeader, IRoot } from "redux/models";
import {
  NotCancelErrorPromise,
  getProjectIdFromUrl,
  getTaskIdFromUrl,
} from "../../../utils/helpers";
import ProjectsConnection from "../../../utils/connections/projects";
import { SET_HEADER, SET_PROJECT } from "../../../redux/actions";
import { IProject } from "../../../utils/models";
import { allRoutes } from "routes/routes";
import { useLocation, matchPath, Link, generatePath } from "react-router-dom";
import { Breadcrumb } from "rsuite";
import BreadcrumbItem from "rsuite/esm/Breadcrumb/BreadcrumbItem";
import { Header as RHeader } from "rsuite";

const Header: FunctionComponent = () => {
  const headerStore = useSelector((state: IRoot) => state.header);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const breadcrumbs = allRoutes.filter(
    (r) => r.breadcrumb && matchPath(pathname, r.path) !== null
  );

  const loadProjectHeader = () => {
    const projectIdFromUrl = getProjectIdFromUrl();
    if (projectIdFromUrl === null) {
      dispatch({ type: SET_HEADER, payload: { name: "", subName: "" } });
      return false;
    }

    if (headerStore.nameUrl === undefined) {
      headerStore.nameUrl = "";
    }

    const nameUrlParts = headerStore.nameUrl.split("/");
    if (nameUrlParts[2] === projectIdFromUrl) {
      return false;
    }

    ProjectsConnection.getProject(projectIdFromUrl).then((response) => {
      const projectReadModel: IProject = response.data;
      const headerData: IHeader = {
        subNameUrl: "",
        subName: "",
        name: projectReadModel.name,
        nameUrl: `/projects/${projectIdFromUrl}/visits`,
      };
      dispatch({ type: SET_HEADER, payload: headerData });
      dispatch({ type: SET_PROJECT, payload: projectReadModel });
    }, NotCancelErrorPromise);
  };

  useEffect(() => {
    loadProjectHeader();
  }, [pathname]);

  const getUrlFromPath = (path: string) => {
    const params = {};
    if (path.includes(":id")) {
      // @ts-ignore
      params.id = getProjectIdFromUrl();
    }
    if (path.includes(":taskId")) {
      // @ts-ignore
      params.taskId = getTaskIdFromUrl();
    }

    const pathParam = matchPath(pathname, path);
    // @ts-ignore
    if (!params.id && pathParam?.params?.id) {
      // @ts-ignore
      params.id = pathParam.params.id;
    }
    return generatePath(path, { ...pathParam?.params, ...params });
  };

  return (
    <RHeader
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "7px 20px",
        alignItems: "center",
        boxShadow: "0 12px 32px 0 rgba(93,92,92,.12)",
      }}>
      <div key={"headerCol1"} className={styles.breadCrumbWrapper}>
        <Breadcrumb key={"breadCrumbContainer"} separator={">"}>
          {breadcrumbs &&
            breadcrumbs.map((r, i) => {
              const isLast = i === breadcrumbs.length - 1;
              const label =
                typeof r.breadcrumb === "string"
                  ? r.breadcrumb
                  : r.breadcrumb && <r.breadcrumb />;
              return (
                <BreadcrumbItem
                  active={isLast}
                  as={"span"}
                  key={`breadcrumbItem${i}`}>
                  {isLast ? (
                    label
                  ) : (
                    <Link
                      to={getUrlFromPath(r.path)}
                      key={`breadcrumbLink${i}`}>
                      {label}
                    </Link>
                  )}
                </BreadcrumbItem>
              );
            })}
        </Breadcrumb>
      </div>

      <div key={"headerCol2"} className={styles.flexGrow}>
        <div className={styles.flex} id="mobile-menu-button" />
        <div
          key={"filterRow"}
          style={{ justifyContent: "flex-start", marginLeft: "15px" }}
          className={styles.flexGrow}
          id="filter-row"
        />
        <div
          key={"headerButtons"}
          className={styles.flex}
          id="header-buttons"
        />
        <div key={"filterButtons"} className={styles.flex} id="filter-button" />
        <User key={"userButton"} />
      </div>
    </RHeader>
  );
};

export default Header;
